// .messages {
//     .message__type {
//         color: #d6d6d6;
//         margin-right: 1ch;
//     }

//     p {
//         margin-bottom: 0;
//     }
// }

// .results {
//     display: flex;
//     align-items: baseline;
//     padding: 16px;
//     border: 1px solid #d6d6d6;
//     border-radius: 4px;

//     &__title {
//         flex: 0;
//         margin-bottom: 0;
//     }

//     p {
//         flex: 1;
//         margin-bottom: 0;
//     }
// }

// $slider-height: 8;
// $ball-height: $slider-height * 1.5;

// .slider {
//     position: relative;
//     width: 100%;
//     height: #{$slider-height}px;
//     background-color: #eee;
//     border-radius: #{$slider-height * 2}px;
//     .messages {
//         display: none;
//     }

//     &__bar {
//         display: flex;
//         align-items: center;
//         background-color: #090;
//         position: absolute;
//         top: 0;
//         bottom: 0;
//     }

//     &__ball {
//         position: absolute;
//         display: block;
//         height: #{$ball-height}px;
//         width: #{$ball-height}px;
//         border-radius: 50%;
//         background-color: #090;
//         transform: scale(1);
//         transition: transform 100ms ease-in-out;
//         cursor: pointer;

//         &#min {
//             left: calc(0% - #{$ball-height / 2}px);
//         }

//         &#max {
//             right: calc(0% - #{$ball-height / 2}px);
//         }

//         &:hover {
//             transform: scale(1.2);
//         }
//     }
// }

// .slider-min-control.hidden,
// .slider-max-control.hidden {
//     position: absolute !important;
//     left: -999% !important;
//     top: -999% !important;
// }

.min-max-slider {
    position: relative;
    // width: 100%;
    width: 180px;
    text-align: center;
    // span.lower,
    // span.upper {
    //     position: absolute;
    //     top: 0;
    // }
    // span.lower {
    //     left: 0;
    // }
    // span.upper {
    //     right: 0;
    // }
    // &-lower,
    // &-upper {
    //     position: absolute;
    //     top: 0;
    // }
    // &-lower {
    //     left: 0;
    // }
    // &-upper {
    //     right: 0;
    // }
    // margin-bottom: 50px;
    .ranges {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }
    // input[type="range"]::-webkit-slider-thumb {
    //     -webkit-appearance: none;
    //     border: 1px solid #000000;
    //     height: 36px;
    //     width: 16px;
    //     border-radius: 3px;
    //     background: #ffffff;
    //     cursor: pointer;
    //     margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
    //     box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
    // }
}
.min-max-slider > label {
    display: none;
}
span.value {
    height: 1.7em;
    font-weight: bold;
    display: inline-block;
}
span.value.lower::before {
    // content: "€";
    display: inline-block;
}
span.value.upper::before {
    // content: "- €";
    display: inline-block;
    margin-left: 0.4em;
}
.min-max-slider > .legend {
    display: flex;
    justify-content: space-between;
}
.min-max-slider > .legend > * {
    font-size: small;
    opacity: 0.25;
}
.min-max-slider > input {
    cursor: pointer;
    position: absolute;
}

/* webkit specific styling */
.min-max-slider > input {
    -webkit-appearance: none;
    outline: none !important;
    background: transparent;
    background-image: linear-gradient(
        to bottom,
        transparent 0%,
        transparent 30%,
        lighten($color-secondary, 15%) 30%,
        lighten($color-secondary, 15%) 60%,
        transparent 60%,
        transparent 100%
    );
}
.min-max-slider > input::-webkit-slider-thumb {
    -webkit-appearance: none; /* Override default look */
    appearance: none;
    width: 1rem; /* Set a specific slider handle width */
    height: 1rem; /* Slider handle height */
    background: lighten($color-secondary, 1%); /* Green background */
    cursor: pointer; /* Cursor on hover */
    border: 1px solid $color-secondary;
    border-radius: 100%;
}
.min-max-slider > input::-webkit-slider-runnable-track {
    cursor: pointer;
}
