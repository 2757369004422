a {
    color: $color-red-prim;
    &:hover {
        color: $color-primary;
    }

    &:hover i {
        color: $color-red-prim;
    }
}
%button {
    background-color: $color-red-prim;
    color: #fff;
    border: none;
    padding: 1rem 1.5rem;
    height: 40px;
    border-radius: 36px;
    line-height: .5rem;
    transition: background-color 0.2s;
}
%button1 {
    background-color: $color-red-prim;
    color: #fff;
    border: none;
    padding: 1rem 1.5rem;
    height: 40px;
    border-radius: 36px;
    line-height: .5rem;
    transition: background-color 0.2s;
}

.booking_search-button {
    display: flex;
    align-items: center;
    padding: .75rem 1.5rem;
    background-color: $color-red-prim;
    cursor: pointer;
    border-radius: 36px;
    height: 56px;
    transition: background-color 0.2s;
    &-svg {
        // transform: rotate(-deg);
        fill: #fff;
        margin-left: 0.5rem;
    }
    .button_submit {
        padding-right: 0;
    }
    &:hover {
        background-color: $color-primary;
    }
    &:hover .button_submit {
        background-color: $color-primary;
    }
}

.button {
    display: inline-block;
    &:link,
    &:visited {
        white-space: nowrap;
        @extend %button;
        &:hover {
            color: #fff;
            // transform: translateY(-10px);
        }
    }

    &_submit {
        @extend %button;
        &:focus {
            border: none;
            outline: none;
        }
        &:hover {
            background-color: $color-secondary;
        }
    }
    &_submit1 {
        @extend %button1;
        &:focus {
            border: none;
            outline: none;
        }
        &:hover {
            background-color: $color-secondary;
        }
    }
    &_svg {
        position: absolute;
        top: 0;
        right: 15px;
        #rect {
            stroke: 5px;
            fill: yellow;
        }
        // stroke-dasharray: 314.3px;
        // stroke-dashoffset: 314.3px;

        // z-index: 9;
    }
    &:hover {
        background-color: $color-secondary;
        &_svg {
            #rect {
                stroke: 10px;
                fill: red;
            }
        }
    }
}

.booking_form-submit:hover {
    .button_svg {
        stroke: 10px;
    }
}
