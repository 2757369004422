.flightSearch {
    &_div {
        background-color: yellow;
    }
    &_table {
        // margin-top: 1rem;
        // table-layout: fixed;
        // border-collapse: separate;
        // border-spacing: 5em;
        border-top: none;
    }
    &_thead {
        border-top: 1px solid #dee2e6;
        color: $color-font-dark;
        position: relative;

        tr {
            background-color: lighten($color-secondary, 36%);
        }
        th {
            padding-top: 1rem;
            padding-bottom: 1rem;
            white-space: nowrap;
        }
    }
    &_tbody {
        td {
            vertical-align: middle;
            padding-top: 1.5rem;
            padding-bottom: 1.5rem;
        }
        tr:hover {
            border-bottom-color: $color-secondary;
            background-color: lighten($color-secondary, 36%);
        }
    }

    &_airlines {
        display: flex;
        justify-content: space-between;
        &-name {
        }
        &-details {
            margin-left: 2rem;
        }
    }
    &_span {
        display: flex;
        flex-direction: column;
        justify-content: center;
        &-top {
            color: $color-font-dark;
        }
        &-bottom {
            color: $color-grey;
            font-size: 12px;
            display: flex;
            align-items: center;
        }
    }
    &_logo {
        width: 50px;
        height: 50px;
        overflow: hidden;
        margin-right: 5px;
        img {
            width: 100%;
            height: auto;
        }
    }
    &_price {
        // color: $color-secondary;
        font-weight: bold;
        white-space: nowrap;
    }
    &_svg {
        fill: $color-grey;
        margin: 0 2px;
    }
    &_summary {
        // height: 3.5rem;
        // line-height: 3.5rem;
        padding: 0 1rem;
        color: $color-font-dark;
        background-color: $color-primary;
        background-color: #0c4da1;
        background-color: $color-font-dark-1;
        display: flex;
        position: relative;
        z-index: 1;
        &-col {
            z-index: 2;
            background-color: #0c4da1;
            background-color: lighten($color-secondary, 36%);
            border-left: 5px solid $color-secondary;
        }
        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 100%;
        //     height: 100%;
        //     top: 0;
        //     left: 50%;
        //     background-color: #0c4da1;
        //     background-color: lighten($color-secondary, 36%);
        //     z-index: 1;
        // }
        &-pass {
            span {
                color: #fff;
                &:not(:last-child) {
                    margin-right: 1rem;
                }
            }
        }
        &-info {
            display: flex;
            &-container {
                display: flex;
                align-items: center;
                svg {
                    margin: 0 0.5rem;
                }
                &:not(:first-child) {
                    margin-left: 0.5rem;
                    padding-left: 0.5rem;
                    border-left: 2px solid #fff;
                }
            }
        }
        &-country {
            // &:first-child {
            //     margin-right: 0.5rem;
            // }
            // &:last-child {
            //     margin-left: 0.5rem;
            // }
        }
    }
    &_tr {
        &-panel {
            td {
                padding: 0;
            }
        }
    }
    &_panel {
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.4s;
    }
}
