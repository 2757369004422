.dates {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-template-rows: repeat(2, min-content);
    // grid-column-gap: 8px;
    // border: 1px solid #e0e0e0;
    // @include respond(tab-portrait) {
    //     grid-template-columns: repeat(3, 1fr);
    //     grid-auto-rows: min-content;
    // }

    @include respond(phone) {
        // grid-template-columns: repeat(4, 1fr);
    }

    position: relative;
    &_heading {
        grid-column: 1 / -1;
        grid-row: 1 / span 1;
    }

    &_top {
        margin-bottom: 2px;
    }
    &_bottom {
        font-weight: bold;
        @include respond(col-md) {
            font-size: 12px;
        }
    }

    &_day {
        &-mobile {
            display: none;
            @include respond(col-md) {
                display: inline-block;
            }
        }
        &-desktop {
            @include respond(col-md) {
                display: none;
            }
        }
    }

    &_box {
        padding: 8px;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        cursor: pointer;
        z-index: 1;
        @include respond(col-md) {
            padding: 2px;
        }
        &:before {
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            content: "";
            height: 2px;
            background-color: $color-primary;
            z-index: 2;
        }
        &:not(:last-child):before {
            width: calc(100% + 1px);
        }
        &-hovered {
            color: #fff;
            background-color: lighten($color-secondary, 8%);
        }
        &-link {
            position: absolute;
            display: inline-block;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            z-index: 5;
        }
        &:not(:last-child) {
            border-right: 1px solid lighten($color-secondary, 25%);
        }
        &:hover &:after {
            bottom: 0;
            opacity: 1;
            visibility: visible;
        }
        &:first-of-type {
            border-top-left-radius: 5px;
        }
        &:last-child {
            border-top-right-radius: 5px;
        }
        &:after {
            content: "";
            position: absolute;
            bottom: -4px;
            left: 0;
            width: 100%;
            height: 4px;
            background-color: $color-secondary;
            transition: all 0.3s;
            opacity: 0;
            visibility: hidden;
            z-index: -1;
        }

        &-selected {
            color: #fff;
            &:after {
                bottom: 2px;
                opacity: 1;
                height: calc(100% - 2px);
                visibility: visible;
            }
        }
    }
}
