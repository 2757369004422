%form_field {
    padding: 0 10px;
    height: 40px;
    line-height: 40px;
    border-radius: 5px;
    border: 1px solid $color-grey;
    border: 1px solid lighten($color-grey, 20%);
    &:hover {
        border: 1px solid $color-secondary;
    }
    &:focus {
        outline: none;
        border: 1px solid $color-secondary;
    }
    &::placeholder {
        color: $color-grey;
    }
}
%form_field2 {
    height: 40px;
    line-height: 40px;
    &:focus {
        outline: none;
    }
    &::placeholder {
        color: $color-grey;
    }
}

.show_options {
    color: #fff;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    svg {
        transition: all 0.4s;
        margin-right: 5px;
    }
    &-active {
        svg {
            transform: rotate(-180deg);
        }
    }
    &-hidden {
        opacity: 0;
        visibility: hidden;
    }
    &-visible {
        opacity: 1;
        visibility: visible;
    }
}

.content_home {
    .booking_check-option {
        label {
            color: #000;
        }
    }
}
.booking {
    // min-height: 100vh;
    position: relative;
    padding: 4rem 0;
    min-height: 60vh;
    // background-image: linear-gradient(
    //         rgba($search-flight-background, 0.2),
    //         rgba($search-flight-background, 0.2)
    //     ),
    //     url(/img/booking.jpg);
    background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
        url(/img/booking.jpg);
    // background-image: linear-gradient(
    //         rgba($color-primary, 0.5),
    //         rgba($color-primary, 0.5)
    //     ),
    //     url(/img/booking.jpg);
    background-position: center;
    background-size: cover;
    // background-attachment: fixed;
    display: flex;
    // justify-content: center;
    // align-items: center;
    flex-direction: column;
    &_forms-wrapper {
    }

    // Booking radios
    &_check {
        margin-bottom: 1rem;
        position: relative;
        &-option {
            display: inline-block;
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            cursor: pointer;
            position: relative;

            // &:after {
            //     content: "";
            //     position: absolute;
            //     top: 50%;
            //     right: -10px;
            //     transform: translate(50%,20%);
            //     display: inline-block;
            //     width: 0;
            //     height: 0;
            //     border-left: 6px solid transparent;
            //     border-right: 6px solid transparent;
            //     border-top: 6px solid $color-red-prim;
            //     opacity: 0;
            //     visibility: hidden;
            //     transition: .3s opacity;
            // }
            &:hover{
                color: $color-red-prim;
            }
            svg {
                fill: $color-font-dark;
                margin-right: 5px;
            }
            &:not(:last-child) {
                margin-right: 2rem;
            }
            label {
                color: #fff;
                margin-bottom: 0;
                cursor: pointer;
                color: $color-font-dark;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                padding: 1rem 0 .5rem;
            }
            input {
                display: none;
            }
            &.booking_check-default {
                // background-image: linear-gradient(
                //     rgba(0, 0, 0, 0.9),
                //     rgba(0, 0, 0, 0.9)
                // );
                svg {
                    fill: #fff;
                }
                label {
                    color: $color-red-prim;
                }
                &::after {
                    content: "";
                    position: absolute;
                    top: 50%;
                    right: -10px;
                    transform: translate(50%,20%);
                    display: inline-block;
                    width: 0;
                    height: 0;
                    border-left: 6px solid transparent;
                    border-right: 6px solid transparent;
                    border-top: 6px solid $color-red-prim;
                }
            }
        }
    }

    // Booking Tabs

    &_tabs {
        &-button {
            position: relative;
            cursor: pointer;
            display: inline-block;
            color: #fff;
            z-index: 5;
            min-height: 50px;
            background-color: $color-primary;
            margin-right: .1rem;
            padding-left: .5rem;
            padding-right: .5rem;
            &.general_tabs-button-active {
               background-color: #fff;
               color: #000;
               .svg{
                fill: #fff;
               }
                &:hover:before {
                    display: none;
                }
            }
            &:hover:before {
               background-color: #fff;
            }
        }
        &-container {
            grid-row: 1 / span 1;
            grid-column: 1 / -1;
        }
    }

    // Forms designs

    &_forms {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(2, min-content);
        // margin-top: 2rem;
        align-items: start;
        &-wrapper {
            // background-image: linear-gradient(
            //     rgba(0, 0, 0, 0.5),
            //     rgba(0, 0, 0, 0.5)
            // );
            min-height: 204px;
            background-color: #fff;
            grid-column: 1 / -1;
            grid-row: 2 / -1;
            border-bottom-left-radius:20px;
            border-bottom-right-radius:20px;
            border-top-right-radius:20px;
            padding: 0 3rem 2rem 3rem;
            @include respond(phone) {
                padding: 0 2rem 2rem 2rem;
                padding: 0 10px 10px 10px;
            }
            background-image: #fff;
        }
    }

    &_form {
        // background-image: linear-gradient(
        //     rgba($color-primary, 0.7),
        //     rgba($color-primary, 0.7)
        // );

        // background-color: #fff;
        &-submit {
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            position: absolute;
            right: 2rem;
            bottom: -1.5rem;
            align-items: flex-end;
        }
        &-submits {
            display: inline-flex;
            flex: 1;
            justify-content: flex-end;
            align-items: flex-end;
        }
        color: #fff;
        color: $color-grey;
        // grid-column: 1 / -1;
        // grid-row: 2 / -1;

        // Travellers styles
        &-one {
            display: block;
        }
        &-multi {
            display: none;
        }
        &-travel {
            position: relative;
            &-box {
                position: absolute;
                top: 100%;
                left: 0;
                width: 100%;
                background-image: linear-gradient(
                    rgba(0, 0, 0, 0.7),
                    rgba(0, 0, 0, 0.7)
                );
                padding: 1rem;
                display: grid;
                grid-row-gap: 1rem;
                grid-auto-rows: min-content;
            }
            &-input {
                position: relative;
                display: flex;
                input {
                    height: 30px;

                    border-radius: 5px;
                }
            }
            &-adults {
                display: inline-block;
                height: 40px;
                line-height: 40px;
                flex: 0 1 60%;
                text-align: center;
                background-color: #fff;
                color: darken($color-grey, 20%);
                border-radius: 2px;
            }
            &-num {
            }
            &-inc,
            &-dec {
                height: 40px;
                line-height: 40px;
                display: inline-block;
                flex: 1;
                span {
                    padding-top: 100%;
                }
            }
            &-button {
                height: 40px;
                line-height: 40px;
                color: darken($color-grey, 20%);
                border-radius: 5px;
            }
        }
        &-row {
            > div:not(:last-child) {
                .booking_form-fields-col {
                    @media (max-width: 991px) {
                        // margin-bottom: 2rem;
                    }
                }
            }
            &:not(:last-child) {
                margin-bottom: .5rem;
                @include respond(col-md) {
                    // margin-bottom: 0;
                }
            }
            @include respond(col-md) {
                &:last-child {
                    margin-top: 1rem;
                }
            }
            > div:not(:last-child) {
                @include respond(col-md) {
                    margin-bottom:0;
                }
            }
            .booking_form_direction{
                display: flex;
                align-items: center;
                justify-content: center;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%,-50%);
                border-radius: 50%;
                border: .1px solid $color-grey;
                width: 50px;
                height: 50px;
                background-color: #fff;
            }
        }

        &-select {
            padding: 0 0.5rem;
            height: 40px;
            line-height: 40px;
            border-radius: 5px;
            border: 2px solid $color-grey;
            color: $color-grey;
            &:focus {
                outline: none;
            }
        }

        &-fields {
            &-col {
                input.form-control,
                select {
                    background-color: #fff;
                    // border: 1px solid lighten($color-grey, 25%);
                    height: 40px !important;
                    &:hover {
                        // border: 1px solid $color-secondary;
                        border: none;
                    }

                    &:focus {
                        outline: none;
                    }
                }

                select:focus {
                    // border: 1px solid $color-secondary;
                }

                &:hover label:before {
                    // border: 1px solid $color-secondary;
                    border-bottom: none;
                }
                textarea {
                    border-radius: 5px;
                    background-color: #fff;
                    border: 1px solid lighten($color-grey, 20%);
                    padding: 10px;
                    &:hover {
                        border: 1px solid $color-secondary;
                    }
                    &:focus {
                        outline: none;
                        border: 1px solid $color-secondary;
                    }
                }
                textarea:-webkit-autofill,
                textarea:-webkit-autofill:hover,
                textarea:-webkit-autofill:focus {
                    background-color: #fff;
                    -webkit-box-shadow: 0 0 0px 1000px
                        lighten($color-secondary, 37%) inset;
                }
                .list-group {
                    max-height: 250px;
                    overflow-y: scroll;
                    width: 100%;
                    &::-webkit-scrollbar {
                        width: 5px;
                        height: 0px;
                    }

                    /* Track */
                    &::-webkit-scrollbar-track {
                        box-shadow: inset 0 0 10px rgba(68, 76, 89, 0.25);
                        border-radius: 5px;
                    }

                    /* Handle */
                    &::-webkit-scrollbar-thumb {
                        border-radius: 5px;
                        background: $color-secondary;
                    }
                    a {
                        padding: 10px;
                        color: $color-font-dark;
                        transition: all 0.2s;
                        &:not(:last-child) {
                            border-bottom: 1px solid $color-grey-light;
                        }
                        &:hover {
                            color: #fff;
                            background-color: $color-font-dark;
                        }
                    }
                }
                position: relative;
                display: flex;
                flex-direction: column;
                // label {
                //     position: absolute;
                //     top: -3px;
                //     left: 0;
                //     color: #fff;
                //     color: $color-font-dark;
                //     font-weight: bold;
                //     font-size: 12px;
                //     transform: translateY(-50%);
                //     padding: 2px 10px;
                //     margin-bottom: 0;
                //     background-color: #fff;
                //     background-color: transparent;
                //     z-index: 2;
                //     &:before,
                //     &:after {
                //         content: "";
                //         position: absolute;
                //         display: inline-block;
                //         left: 0;
                //         z-index: -1;
                //     }
                // }
                &-disabled {
                    // To hide we need to use these two properties
                    // opacity: 0;
                    // visibility: hidden;
                    &:hover label:before {
                        border: 1px solid lighten($color-grey, 25%);
                    }
                    label {
                        color: lighten($color-font-dark, 50%);
                    }
                    input {
                        pointer-events: none;
                        color: lighten($color-font-dark, 50%);

                        &::placeholder {
                            color: lighten($color-grey, 10%);
                        }
                    }
                }
                &:hover {
                    select,
                    textarea {
                        border: 1px solid $color-secondary;
                    }
                }
                &-focused {
                    label:before {
                        border: 1px solid $color-secondary;
                        border-bottom: none;
                    }
                }
                @include respond(col-md) {
                    margin-bottom: 1rem;
                }
            }
            &-input {
                @extend %form_field2;
                width: 100%;
                background-color: rgba(255, 255, 255, 1) !important;
            }
            &-select {
                @extend %form_field2;
                background-color: rgba(255, 255, 255, 1) !important;
            }
        }
       .economy_Channel{
        position: absolute;
       }
    }
    &_depart {
        &-one,
        &-round {
            display: none;
            &-active {
                display: block;
            }
        }

        &-dom-one,
        &-dom-round {
            display: none;
            &-active {
                display: block;
            }
        }
    }
}

.multi {
    &_add-flight {
        color: #fff;
        svg {
            margin-left: 5px;
            margin-bottom: 2px;
        }
    }
    &_wrapper {
        margin-bottom: 1rem;
        cursor: pointer;
        display: flex;
    }
    &_flight {
        // cursor: pointer;
        margin-right: 1rem;
        color: $color-font-dark;
        display: inline-flex;
        align-items: center;
        position: relative;
        &_close {
            position: absolute;
        }
        label {
            margin-bottom: 0;
            color: #000;
        }
        svg {
            transform: rotate(45deg);
            margin-right: 5px;
        }
        &-add {
            color: $color-font-dark;
        }
        input {
            display: none;
        }
        &-row {
            display: none;
            position: relative;
            &-svg {
                &-close {
                    position: absolute;
                    top: 16px;
                    right: 15px;
                    cursor: pointer;
                    display: inline-block;
                }
            }
            &.multi_flight-row-active {
                display: flex;
            }
        }
    }
}
