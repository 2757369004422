@mixin flex_justify-center {
    display: flex;
    justify-content: center;
}

@mixin tabs_button {
    position: relative;
    cursor: pointer;
    padding-bottom: 0.5rem;
    // display: inline-block;
    color: #fff;
}

@mixin bottom_underline {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    height: 5px;
    width: 100%;
    background-color: #f4a648;
}

@mixin relative169 {
    padding-top: 56.25%;
    position: relative;
}

@mixin absolute169 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

@mixin respond($break-point) {
    @if ($break-point == verysmall-screen) {
        @media (max-width: 380px) {
            @content;
        }
    }
    // @if ($break-point == verysmall-phone) {
    //     @media (max-width: 400px) {
    //         @content;
    //     }
    // }
    @if ($break-point == small-phone) {
        @media (max-width: 400px) {
            @content;
        }
    }
    @if ($break-point == phone) {
        @media (max-width: 576px) {
            @content;
        }
    }

    @if ($break-point == col-md) {
        @media (max-width: 768px) {
            @content;
        }
    }

    @if ($break-point == phone-landscape) {
        @media (max-width: 780px) {
            @content;
        }
    }

    @if ($break-point == tab-portrait) {
        @media (max-width: 900px) {
            @content;
        }
    }

    @if ($break-point == tab-landscape) {
        @media (max-width: 1000px) {
            @content;
        }
    }
    @if ($break-point == tab-landscape-max) {
        @media (max-width: 1024px) {
            @content;
        }
    }

    @if ($break-point == stab-landscape-max) {
        @media (max-width: 1023px) {
            @content;
        }
    }
    @if ($break-point == stab-landscape-submax) {
        @media (max-width: 1100px) {
            @content;
        }
    }

    @if ($break-point == small-desktop) {
        @media (max-width: 1200px) {
            @content;
        }
    }
    @if ($break-point == semi-desktop) {
        @media (max-width: 1350px) {
            @content;
        }
    }
    @if ($break-point == medium-desktop) {
        @media (max-width: 1400px) {
            @content;
        }
    }
    @if ($break-point == balance-desktop) {
        @media (max-width: 1450px) {
            @content;
        }
    }

    @if ($break-point == big-desktop) {
        @media (min-width: 1800px) {
            @content;
        }
    }
}
