%buttonCards {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    border-radius: 5px;
    border: 1px solid darken($color-secondary-lighter, 25%);
    &:hover {
        border: 1px solid darken($color-secondary-lighter, 50%);
    }
    &.button_card {
        padding: 5px 1rem;
        color: $color-secondary;
        transition: all 0.4s;
        @include respond(phone-landscape) {
            margin-bottom: 8px;
        }
        &:hover {
            color: $color-primary;
        }
    }
    &.button_admin {
        font-weight: bold;
        padding: 2.5rem 2rem;
    }
}
.ticket {
    section {
        padding: 1.5rem 0;
        position: relative;
        &:after {
            // position: absolute;
            // content: "";
            // bottom: 0;
            // left: 50%;
            // width: 85%;
            // transform: translateX(-50%);
            // height: 1px;
            // background-color: $color-grey;
        }
    }

    &_payrow {
        display: flex;
        &-col {
            &-admin {
                form {
                    height: 100%;
                }
                .button_admin {
                    height: 100%;
                    border: 1px solid darken($color-secondary-lighter, 25%);
                    border-radius: 5px;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    padding: 5px 1rem;
                    justify-content: center;
                    position: relative;
                    strong {
                        color: $color-secondary;
                    }
                    svg {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        display: none;
                    }
                    &:hover {
                        border: 1px solid darken($color-secondary-lighter, 50%);
                    }
                    &:hover strong {
                        color: $color-primary;
                    }
                }
            }
            &:not(:last-child) {
                margin-right: 1rem;
            }
            &-img {
                display: inline-block;
                position: relative;
                // width: 100%;
                // padding-top: 100%;
                height: 92px;
                margin-bottom: 2px;

                img {
                    // position: absolute;
                    // top: 0;
                    // left: 0;
                    // width: 100%;
                    height: 100%;
                    // width: auto;
                    object-fit: contain;
                }
            }
            .button_balance {
                @extend %buttonCards;
            }
            a {
                @extend %buttonCards;
            }
        }
    }

    &_fareRow {
        position: relative;
        padding-bottom: 2rem;
        &-read {
            position: absolute;
            bottom: 1rem;
            right: 1rem;
        }
    }

    &_fareCol {
        max-height: 500px;
        overflow: hidden;
        position: relative;
        transition: all 0.4s;
    }

    &_col {
        &-heading {
            padding-top: 1rem;
            background-color: $color-secondary-lighter;
            padding-bottom: 1rem;
            border-bottom: 1px solid darken($color-secondary-lighter, 10%);
            // color: darken($color-secondary-lighter, 60%);
        }
    }

    &_flights,
    &_rules {
        .ticket_border {
            padding-top: 0;
            padding-bottom: 0;
        }
    }

    &_rules {
        &-p {
            margin-top: 1rem;
        }
        &-ul {
            li {
                margin-bottom: 0.5rem;
            }
        }
    }

    &_heading {
        font-weight: bold;
    }

    .search_flight-details-content {
        padding-left: 0;
        padding-right: 0;
        &:not(:last-child) {
            border-bottom: 1px solid lighten($color-grey, 25%);
        }
    }

    &_border {
        border: 1px solid darken($color-secondary-lighter, 10%);
        border: 1px solid lighten($color-grey, 25%);
        border-radius: 5px;
        padding-top: 1rem;
        padding-bottom: 1rem;
    }
    &_top {
        &-info-name {
            font-weight: bold;
            color: #fff;
        }
        &-info-p {
            margin-bottom: 0;
            line-height: 1.6;
            margin-top: 0.5rem;
            color: #fff;
        }
        .ticket_border {
            background-color: $color-primary-dark;
        }
    }
    > .container {
        background-color: #fff;
    }
    &_sum {
        &-info {
            padding: 1rem;
            border: 1px solid lighten($color-grey, 30%);
            background-color: $color-secondary-lighter;
        }
        &-field {
            // font-weight: bold;
            // color: darken($color-secondary-lighter, 60%);
            &-flight {
                margin-bottom: 1rem;
            }
        }
        &-value {
            display: grid;
            grid-row-gap: 0.5rem;
            &-row {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }
        &-row {
            &:last-child {
                .ticket_sum-col-field {
                    border-bottom: none;
                }
            }
        }
        &-col {
            padding-top: 1rem;
            padding-bottom: 1rem;
            &-field {
                background-color: $color-secondary-lighter;
                border-bottom: 1px solid darken($color-secondary-lighter, 10%);
            }
            &-value {
                border-bottom: 1px solid lighten($color-grey, 30%);
            }
        }

        .ticket_border {
            padding-top: 0;
            padding-bottom: 0;
        }
    }
}
