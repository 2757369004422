.selection {
    width: 100%;
    position: fixed;
    bottom: 0px;
    left: 0;
    background-color: #fff;
    background-color: $color-primary-dark;
    padding: 2rem 0;
    z-index: 9999;
    transform: translateY(100%);
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s;
    @include respond(tab-portrait) {
        padding: 1rem 0;
        bottom: 44px;
    }
    &_depart {
        @include respond(tab-portrait) {
            grid-row: 1 / span 1;
            grid-column: 1 / -1;
        }
    }
    &_return {
        @include respond(tab-portrait) {
            grid-row: 2 / span 1;
            grid-column: 1 / -1;
        }
    }
    &_heading {
        position: absolute;
        top: 0;
        left: 0;
        padding: 0.5rem 1rem;
        transform: translateY(-100%);
        background-color: $color-primary-dark;
        border-top-right-radius: 5px;
        color: #fff;
        cursor: pointer;
    }
    &_active {
        transform: translateY(0);
        opacity: 1;
        visibility: visible;
        &.selection_hiding {
            transform: translateY(100%);
        }
    }

    &_col {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include respond(small-desktop) {
            display: grid;
            grid-template-columns: repeat(2, max-content);
            grid-gap: 1rem;
        }

        @include respond(tab-portrait) {
            grid-template-rows: repeat(3, max-content);
        }
    }
    &_grid {
        display: grid;
        grid-template-columns: repeat(5, max-content);
        grid-gap: 1rem;
        align-items: center;
        color: #fff;
        position: relative;
        @include respond(tab-landscape) {
            grid-template-columns: repeat(3, max-content);
            grid-template-rows: repeat(3, max-content);
        }
        @include respond(tab-portrait) {
            grid-row-gap: 0.5rem;
        }
        .search_flight-airlines-logo {
            grid-template-columns: 1rem 1fr 1rem;
        }
        .search_flight-country-time {
            color: #fff;
        }
        &-depart,
        &-return {
            display: none;
        }
        &-heading {
            grid-column: 1 / -1;
        }
        .search_flight-via {
            @include respond(tab-landscape) {
                grid-row: 3 / span 1;
                grid-column: 1 / span 1;
            }
        }
        > div:last-child {
            @include respond(tab-landscape) {
                grid-row: 3 / span 1;
                grid-column: 2 / span 1;
            }
        }
    }
    &_total {
        opacity: 0;
        visibility: hidden;
        display: flex;
        flex-direction: column;
        color: #fff;
        transition: all 0.4s;
        &-price {
            font-weight: bold;
        }
        &-active {
            opacity: 1;
            visibility: visible;
        }
        @include respond(tab-portrait) {
            grid-column: 1 / span 1;
            grid-row: 3 / span 1;
        }
    }
    &_divider {
        width: 1px;
        height: 100%;
        background-color: #fff;
        @include respond(small-desktop) {
            display: none;
        }
    }
    &_info {
        color: #fff;
        h5 {
            margin-bottom: 0;
        }
    }
    &_confirm {
        opacity: 0;
        visibility: hidden;
        transition: all 0.4s;
        &-active {
            opacity: 1;
            visibility: visible;
        }
        @include respond(tab-portrait) {
            grid-column: 2 / span 1;
            grid-row: 3 / span 1;
        }
    }
    &_total,
    &_confirm {
        @include respond(small-desktop) {
            margin-top: 1.5rem;
        }
    }
}

.search_flight {
    // for domestic one way make the results grid two column
    &-results-oneway {
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 4rem;
        align-items: start;
        @include respond(small-desktop) {
            grid-template-columns: 1fr;
        }
        h3 {
            grid-column: 1 / -1;
            grid-row: 1 / span 1;
        }
    }
    &-results-inbound,
    &-results-outbound {
        .section-header.line-left {
            margin-bottom: 0;
        }
    }
    &-results-inbound {
        padding-left: 2rem;
        @include respond(small-desktop) {
            padding-left: 15px;
            margin-top: 2rem;
        }
    }

    &-results-outbound {
        padding-right: 2rem;
        @include respond(small-desktop) {
            padding-right: 15px;
        }
    }
    &-radio {
        display: inline-block !important;
        padding: 0 !important;
        border: 0 !important;
        z-index: -1 !important;
        position: absolute !important;
        opacity: 0 !important;
    }
}

label.book_flight-button {
    margin-bottom: 0;
    padding: 0.5rem 1rem;
    cursor: pointer;
}

.selected_box {
    > div:first-child {
        &:after {
            // background-color: $color-secondary;
        }
    }
    .book_flight-button:before {
        left: 0;
    }
    .book_flight-button {
        @include respond(small-desktop) {
            background-color: $color-secondary !important;
        }
    }
}
