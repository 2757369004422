.litepicker .container__days .day-item.is-start-date {
    background-color: $color-secondary;
}

.litepicker .container__days .day-item.is-end-date {
    background-color: $color-secondary;
}

.litepicker .container__days .day-item.is-in-range {
    background-color: lighten($color-secondary, 30%);
}
