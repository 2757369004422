.iten {
    &_expand {
        .iten_item-arrow {
            fill: #fff;
        }
    }
    &_expanded {
        .iten_item-arrow {
            transform: rotate(180deg);
        }
    }
    &_item {
        // border: 1px solid darken($color-neutral, 10%);
        // border-radius: 5px;
        // &:not(:last-child) {
        //     margin-bottom: 1rem;
        // }
        cursor: pointer;
        // padding-bottom: 1rem;
        // &:hover {
        //     border: 1px solid lighten($color-primary, 15%);
        // }

        &-arrow {
            transition: all 0.2s;
        }

        &:hover &-arrow {
            fill: lighten($color-primary, 5%);
        }
        // padding: 1rem 1.5rem;
        &-active {
            // border: 1px solid lighten($color-primary, 15%);
            .iten_item-arrow {
                transform: rotate(180deg);
                fill: lighten($color-primary, 5%);
            }
        }
    }

    &_expand {
        // font-size: 1rem;
        cursor: pointer;
    }

    &_heading {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_header {
        // padding: 1rem 1.5rem;
        // display: flex;
        // align-items: center;
        // justify-content: space-between;
    }

    &_body {
        max-height: 0;
        overflow: hidden;
        transition: all 0.4s;
        &-active {
        }
    }
}
