$color-grey: #9d9d9d;
$color-grey-light: #f6f4f4;
$color-grey-light-1: #f2f5f7;
$color-green: #1e9219;
$color-red: #d91d1d;

$color-primary: #1C2B59;
$color-red-prim:#CF2E2E;
$color-light-prim:#F7F7F7;
$color-grey-prim:#707070;

// $color-primary: #1f2e65;

$color-primary-light: #5096c8;
$color-primary-darker: #1C2B59;
$color-primary-dark: #182e3d;
// $color-secondary: #f4a648;
$color-secondary: #1C2B59;
$color-secondary-light: #f7ca94;
$color-secondary-lighter: #fef7ee;
$color-font-dark: #161515;
$color-font-dark-1: #182e3d;

$color-primary-ticket: #1f2e65;

$color-grey-new: #8b949a;

// $font-body: "Roboto", sans-serif;
$font-body: 'Inter', sans-serif;
$font-heading: 'Inter', sans-serif;

$color-primary-light-35: lighten($color-primary-light, 40%);
$color-primary-light-30: lighten($color-primary-light, 30%);
$color-primary-light-20: lighten($color-primary-light, 20%);
$color-grey-prim-35: lighten($color-grey-prim, 35%);
$color-secondary-30: lighten($color-secondary, 30%);
$search-flight-background: #f3faff;

$color-neutral: #f0eceb;
$color-neutral-dark: #393e46;
