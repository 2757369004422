// Fonts
@import url("https://fonts.googleapis.com/css?family=Nunito");

[x-cloak] { display: none !important; }

//Tailwind For Tables
@import "tailwindcss/dist/utilities.min";
@import "@tailwindcss/custom-forms/dist/custom-forms.min";
@import "@tailwindcss/ui/dist/tailwind-ui.min";

// Variables
@import "variables";

// Bootstrap
@import "~bootstrap/scss/bootstrap";
@import "~owl.carousel/dist/assets/owl.carousel.min.css";
@import "~owl.carousel/dist/assets/owl.theme.default.min.css";

// Font-awesome 5.15.4
@import "~@fortawesome/fontawesome-free/css/all.min.css";
@import "~@fortawesome/fontawesome-free/css/solid.min.css";
@import "~@fortawesome/fontawesome-free/css/regular.min.css";
@import "~@fortawesome/fontawesome-free/css/brands.min.css";
@import "~@fortawesome/fontawesome-free/css/svg-with-js.min.css";

//lightbox-2
@import "lightbox2/dist/css/lightbox.min.css";

// Importing style.min.css
@import "styleMin";

//Importing mixin
// @import "mixin";

// Importing custom subik styles
@import "../subik_sass/main";
.primary_header-row {
    background-color: #1C2B59;
}
