.blogs-section {
    padding: 4rem 0;
    &-description {
        > * {
            word-wrap: break-word;
        }
        word-wrap: break-word;
    }
    &-image {
        @include relative169;
        img {
            @include absolute169;
            border-radius: 0.75rem;
        }
        margin-bottom: 1.5rem;
    }
    .links-list {
        h2 {
            color: $color-secondary;
        }
        @media (max-width: 768px) {
            margin-top: 1.5rem;
        }
    }
    &-list {
        &:not(:last-child) {
            padding-bottom: 1rem;
            border-bottom: 1px solid #eaeaea;
        }

        &:not(:first-child) {
            padding-top: 1.2rem;
        }
        img {
            // position: unset !important;
            // transform: unset !important;
        }
    }
}

.blogs-section .links-list ul a {
    padding-left: unset;
    display: flex;
    align-items: center;
    margin-bottom: unset;
    > span:first-child {
        flex: 1 0 20%;
        margin-right: 1rem;
    }

    > span:last-child {
        flex: 1;
    }

    .popular-image {
        display: inline-block;
        width: 100%;
        @include relative169;
        img {
            @include absolute169;
            border-radius: 0.75rem;
        }
    }

    img {
        // position: unset;
        transform: unset;
        border-radius: unset;
    }
}
