.modal {
    position: fixed;
    top: 50%;
    width: 60%;
    height: 90%;
    overflow-y: auto;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 9999;
    transition: all 0.5s;
    padding: 3rem;
    border-radius: 5px;
    display: none;
    background-color: #fff;
    @include respond(small-desktop) {
        width: 85%;
        padding: 3rem 1rem;
    }
    &::-webkit-scrollbar {
        width: 5px;
        height: 0px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
        background: darken($color-secondary-lighter, 5%);
        // box-shadow: inset 0 0 10px rgba(68, 76, 89, 0.25);
        border-radius: 5px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        background: $color-grey;
        background-color: lighten($color-secondary, 10%);
    }
    // FARE RULES BUTTON TO BE PLACED FIXED
    &_button-fare {
        position: fixed;
        bottom: 0;
        left: 0;
        z-index: 999999;
        text-align: center;
        padding: 16px 0;
        background-color: #fff;
    }
    &-close {
        position: fixed;
        // right: 1rem;
        top: 1rem;
        cursor: pointer;
        z-index: 999999;
        opacity: 0;
        visibility: hidden;
        transition: transform 0.3s;

        &-active {
            opacity: 1;
            visibility: visible;
        }
        &:hover {
            transform: rotate(-90deg);
        }
        &:hover svg {
            fill: $color-secondary;
        }
        svg {
            fill: $color-primary;
        }
    }
    &-active {
        display: block;
    }

    &_overlay {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.2);
        -webkit-backdrop-filter: blur(3px);
        backdrop-filter: blur(3px);
        z-index: 999;
        &-hidden {
            display: none;
        }
    }

    &-fareloader {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}

#termsModal {
    z-index: 999999;
}

#fareModal {
    z-index: 99999;
    h3 {
        font-size: 22px;
    }

    #fare-rules {
        padding: 0 1rem;
    }
}
