.rotate_90 {
    transform: rotate(90deg);
}

.text_center {
    text-align: center;
}

.flex_item_full {
    >div {
        flex: 1 0 100%;
    }
}

.overflow_hidden {
    overflow: hidden !important;
    height: 100vh !important;
}

.mrl-05 {
    margin-left: 0.5rem !important;
}

.mrr-05 {
    margin-right: 0.5rem !important;
}

.mrb-1 {
    margin-bottom: 1rem !important;
}

.mrt-1 {
    margin-top: 1rem !important;
}

.mrt-15 {
    margin-top: 1.5rem !important;
}

.mrt-25 {
    margin-top: 2.5rem !important;
}

.mrb-15 {
    margin-bottom: 1.5rem !important;
}

.mrb-25 {
    margin-bottom: 2.5rem !important;
}

.mrb-25 {
    margin-bottom: 2.5rem !important;
}

.panel {
    max-height: 0px;
    overflow: hidden;
    transition: all 0.4s;
}

.l_r {
    display: flex;
    align-items: start;
}
.line_clamp1{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
}
.line_clamp2{
    overflow: hidden;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}
.t_b {
    display: flex;
    flex-direction: column;
}

.inline-block {
    display: inline-block;
}

.flex {
    display: flex;
    align-items: center;

    >*:not(:last-child) {
        margin-right: 1rem;
    }
}

.hidden {
    opacity: 0;
    visibility: hidden;
}
.display-in-lg{
    display: block;
    @include respond (stab-landscape-max){
        display: none;
    }

}

.font-h5 {
    font-size: 9px;
}
.font-10{
    font-size: 10px;
}
.font-h4 {
    font-size: 12px;
}

.font-h3 {
    font-size: 14px;
}

.font-h2 {
    font-size: 16px;
}

.font-h1 {
    font-size: 24px;
}
.font-22{
    font-size: 22px;
}
.font-20 {
    font-size: 20px;
}

.font-weight1 {
    font-weight: 100;
}

.font-weight2 {
    font-weight: 200;
}

.font-weight3 {
    font-weight: 300;
}

.font-weight4 {
    font-weight: 400;
}

.font-weight5 {
    font-weight: 500;
}

.font-weight6 {
    font-weight: 600;
}

.font-weight7 {
    font-weight: 700;
}

.font-weight8 {
    font-weight: 800;
}

.font-weight9 {
    font-weight: 800;
}



.border_top_l_radius {
    border-top-left-radius: 20px;

}
.border-t-l-r{
    border-top-left-radius: 10px;
}
.border-t-r-r{
    border-top-right-radius: 10px;
}
.border_top_r_radius {
    border-top-right-radius: 20px;
}

.border_bottom_l_radius {
    border-bottom-left-radius: 20px;

}

.border_bottom_r_radius {
    border-bottom-right-radius: 20px;
}

.border_all {
    border-radius: 10px;
}
.border_top_all{
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}
.border_bottom_all{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}
.border_20 {
    border-radius: 20px;
}

.absolute1 {
    position: absolute;
}



.title_main {
    position: relative;
    font-size: 24px;
    font-weight: 500;

    &::before {
        content: ' ';
        width: 40px;
        height: 1px;
        background-color: $color-red-prim;
        position: absolute;
        bottom: -3px;
        left: 0;
    }
}
.text-lighter{
    color: $color-light-prim;
}
.text-light-sec{
    color: $color-grey-prim;
}
.color-blue{
  color: $color-secondary;
}
.bg-green{
    background-color: #00A542;
}
.bg-red-light{
    background-color: #CF2E2E1C;
}
.border-lighter{
    border: 0.3px solid $color-light-prim;
}
.border-darker{
    border: 0.3px solid $color-grey-prim-35;

}
.border-b-dark{
    border-bottom: 0.3px solid $color-grey-prim-35;

}
.border-b-light{
    border-bottom: 0.3px solid $color-light-prim;
}
.border-left{
    border-left: 0.3px solid $color-grey-prim-35;
}
.border-black{
    border: 0.3px solid #000000;
}
.title_small {
    position: relative;
    font-weight: 500;

    &::before {
        content: ' ';
        width: 40px;
        height: 1px;
        background-color: $color-red-prim;
        position: absolute;
        bottom: -3px;
        left: 0;
    }
}
.text_black{
    color: #2E2E2E;

}
.color-black{
    color: #000000;
}

.list_heading{
    position: relative;
    max-width: max-content;
    border-bottom: 1.5px solid $color-red-prim;
    // &::before {
    //     content: ' ';
    //     width: 50%;
    //     height: 1px;
    //     background-color: $color-red-prim;
    //     position: absolute;
    //     bottom: -4px;
    //     left: 0;
    // }
}
// .price_old2{
//     &::before{

//     }
// }

.para-color {
    color: $color-grey-prim;
}

.media-padding-0 {
    @include respond(tab-portrait) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.media-padding-1 {
    @include respond(tab-portrait) {
        padding-left: 12px !important;
        padding-right: 12px !important;
    }

    @include respond(small-phone) {
        padding-left: 6px;
        padding-right: 6px;
    }
}

.media-border-top-0 {
    @include respond(tab-portrait) {
        border-top-right-radius: 0px !important;
    }
}

.top_class_custom {
    top: -3rem;
    left: -3rem;
}

.media-padding-2 {
    @include respond(tab-portrait) {
        padding-top: 5px !important;
        padding-bottom: 5px !important;
    }
}

.media-padding-top {
    @include respond(tab-portrait) {
        padding-top: 1rem !important;

    }
}

.media-border-none {
    @include respond(tab-portrait) {
        border: none !important;

    }
}

.z-full {
    z-index: 99999999;
}

.height_custom {
    height: 369px;
}

.background_prim {
    background-color: $color-red-prim;
}

.background_blue_primary {
    background-color: $color-primary;
}

.border-prim-color {
    border-color: $color-primary;
}

.border-red-color {
    border-color: $color-red-prim;
}

.border-l-1 {
    border-left-width: 1px;
}

.media-mt-custom {
    margin-top: 3rem;
}

.lg-flex-reverse {
    @include respond(col-md) {
        flex-direction: column-reverse;
    }
}

.slider_carousel {
    .owl-theme {
        overflow: initial !important;

        .owl-nav {
            margin: 0;

            .owl-next,
            .owl-prev {
                top: 50%;
                transform: translate(0%, -50%);
                position: absolute;
                background: $color-red-prim !important;
                width: 50px;
                height: 50px;
                border-radius: 50%;
                color: #fff !important;
                transition: 0.3s linear;
                margin: 0;
            }

            .owl-next {
                right: -30px;
                font-size: 1.25rem;
                padding: 20px 20px 20px 0;
            }

            .owl-prev {
                left: -30px;
                font-size: 1.4rem;
                padding: 20px 0 20px 20px;
            }
        }
    }
}


.padding_4 {
    padding: 4rem 0;
}

.padding_4_top {
    padding-top: 4rem;
}

// .background_color_prim {
//     background-color: $color-light-prim;

// }
.color_red{
    color: $color-red-prim;
}
.background_color_red {
    background-color: $color-red-prim;

}

.media-flex-col {
    @include respond(small-desktop) {
        flex-direction: column !important;
    }
}

.media-flex-center {
    @include respond(small-desktop) {
        align-items: center !important;
        text-align: center !important;
    }
}

.media-padding-3 {
    @include respond(tab-portrait) {
        padding-top: 2rem !important;
        padding-bottom: 2rem !important;
    }
}

.media-padding-2-top {
    @include respond(tab-portrait) {
        padding-top: 2rem !important;
    }
}

.media-padding-0-top {
    @include respond(tab-portrait) {
        padding-top: 0 !important;
    }
}

.media-padding-0 {
    @include respond(tab-portrait) {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}


.media-padding-half {
    @include respond(tab-portrait) {
        padding-top: 1.5rem !important;
        padding-bottom: 1.5rem !important;
    }
}

.aspect_custom_16 {
    aspect-ratio: 16/9;
}
.aspect_custom_2{
    aspect-ratio: 1/2;
}

.aspect_custom_1 {
    aspect-ratio: 1/1;
}

.translate-y-m {
    transform: translatey(120px);
}

.min-width_6 {
    min-width: 5rem !important;
}

.min-height_6 {
    min-height: 5rem !important;
}

.media-d-none {
    @include respond(small-desktop) {
        display: none !important;
    }
}

.media-d-none-tab {
    @include respond(tab-landscape) {
        display: none !important;
    }
}

.md-col-lg-12 {
    @include respond(tab-landscape) {
        flex: 0 0 100% !important;
        max-width: 100% !important;
    }
}


.select_none {
    -webkit-appearance: none !important;

    padding: auto .4rem;

    &:hover {
        border: none !important;
    }
}



.bottom_tabs_nav {
    display: none;

    @include respond(tab-portrait) {
        display: block;
    }

    .bottom_nav {
        .active1 {
            -moz-box-shadow: inset 0 0 10px #000000;
            -webkit-box-shadow: inset 0 0 10px #000000;
            box-shadow: inset 0 0 10px #000000;
        }
    }
}


.active_arrow {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 25%;
        right: -10px;
        transform: translate(50%, 20%);
        display: inline-block;
        width: 0;
        height: 0;
        border-left: 6px solid transparent;
        border-right: 6px solid transparent;
        border-top: 6px solid #CF2E2E;
    }
}

.opacity-none-media {
    // opacity: 0;
    // visibility: hidden;
    display: none !important;

    @include respond(tab-landscape) {
        // opacity: 1;
        // visibility: visible;
        display: flex !important;
    }
}

.z-99 {
    z-index: 99;
}
.color_yellow{
    color: #FFB000;

}
.min-height-custom {
    min-height: 170px;
}

.overflow-look {
    overflow-y: visible !important;

}

.color-prim {
    color: $color-primary;
}
.bg-prim {
    background-color: $color-red;
}


.media-margin-btm-1 {
    @include respond(tab-portrait) {
        margin-bottom: 1rem !important;
    }
}
.media-margin-btm-2 {
    @include respond(tab-landscape) {
        margin-bottom: 1rem !important;
    }
}

.option-style {
    background-image: url(/img/downarrow.svg);
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    appearance: none !important;
    -webkit-print-color-adjust: exact !important;
    color-adjust: exact !important;
    background-repeat: no-repeat !important;
    background-color: #fff !important;
    padding-top: 0.1875rem !important;
    padding-right: 2.5rem !important;
    padding-bottom: 0.1875rem !important;
    padding-left: 0.75rem !important;
    font-size: 1rem !important;
    line-height: 1.2 !important;
    border-radius: 99999px;
    color: gray;
    background-position: right 0.5rem center !important;
    background-size: 1.5em 1.5em !important;

}

.modal-flight-custom1 {
    height: max-content !important;
}


.media-grid-cols-2 {
    @include respond(tab-landscape) {
        grid-template-columns: repeat(2, minmax(0, 1fr)) !important;
    }
}

.media-grid-cols-1 {
    @include respond(tab-portrait) {
        grid-template-columns: repeat(1, minmax(0, 1fr)) !important;
    }
}

// .media-padding-1{
//     @include respond(tab-landscape){
//         padding-left:1rem !important;
//         padding-right:1rem !important;
//     }
// }

.features_media_img {
    @include respond(small-desktop) {
        width: 6rem !important;
        height: 6rem !important;
        object-fit: contain !important;
    }
}

.sticky-custom {
    position: sticky !important;
    top: 0 !important;
    left: 0 !important;
}

.max-h-viewport {
    max-height: 600px !important;
}
.baggage_info_style{
    color: #9d9d9d;
    font-size: 12px;
}
.z-49 {
    z-index: 99999;
}

.h-16rem {
    height: 16rem !important;

}

.min-4 {
    min-height: 4rem !important;
}

.aspect1611 {
    aspect-ratio: 16/11;
}

.opacity-1 {
    opacity: 1 !important;
}

.right-1rem {
    right: 1rem;
}

.category_tour_act.active {
    background-color: $color-red-prim;
    color: #fff;
}

.category_tour_act:hover {
    background-color: $color-red-prim;
    color: #fff;
}

// .flight-detail-content{
//     display: block !important;
// }
.overflow-x-auto-custom {
    overflow-x: auto;
}

.overflow-y-hidden-custom {
    overflow-y: hidden;
}


.modal-dialog-big {
    width: 1300px !important;
    max-width: 1300px !important;

    @include respond(semi-desktop) {
        width: 90%;
        max-width: 90% !important;
    }
}


.full-view {
    min-height: 80vh;
}

.activeParent {
    label {
        color: #CF2E2E !important;
        &::after {
            content: "";
            position: absolute;
            top: 50%;
            right: -10px;
            transform: translate(50%, 20%);
            display: inline-block;
            width: 0;
            height: 0;
            border-left: 6px solid transparent;
            border-right: 6px solid transparent;
            border-top: 6px solid #CF2E2E;
        }
    }
}

.modal_stick{
    position: sticky;
    bottom: -50px;
    z-index: 99999;
    padding: 1rem;
    background: #fff;
}

.h-230px{
    height: 230px;
}
.truncate{
max-width:100px ;
overflow: hidden;
text-overflow: ellipsis;
white-space: nowrap;
}

.bg-whatsapp{
    background-color: #25D366;
}
.watsh-shadow{
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px !important;
}
.z-999{
    z-index: 999;
}
.ac-modal{
    height: 70% !important;
}
.mb-2p5{
    margin-bottom: 2.5px;
}

.overflow-in{
    overflow: inherit !important;
}


.top_cat{
    @include respond(semi-desktop) {
     top: 10% !important;

    }
}

.text-acustom{
    @include respond (verysmall-screen){
        font-size:12px;
    }
}

.cont-scroll{
    overflow-y: auto;
    overflow-x: hidden;
    &::-webkit-scrollbar {
        width: 5px;
        // display: none;

    }

    /* Track */
    &::-webkit-scrollbar-track {
        background-color: #F7F7F7;
        border-radius: 30px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
        // border-radius: 5px;
        background-color: rgb(30 58 138);
        border-radius: 10px;
        // display: none;
    }
}
// .category_location{
//     overflow-x: scroll;
//     overflow-y: hidden;
//     &::-webkit-scrollbar {
//         width: 5px;
//         height: 5px;
//     }
//     &::-webkit-scrollbar-track {
//         background-color: transparent;
//         border-radius: 10px;
//     }


//     &::-webkit-scrollbar-thumb {
//         background-color: #F7F7F7;
//         border-radius: 5px;

//     }

// }
// .list_styling{
//     trix-editor ul:not(.browser-default) {
//         list-style-type: disc;
//         display: block;
//         padding-left: 40px;
//     }
// }
.browser-default-list{
    >ul{
        list-style: disc;
        list-style-position: outside;
        padding-left: 1rem;
        // >li{
        //     list-style: disc;
        // }
    }
}
// .filter-collapse{
//     position: fixed;
//     right: 0;
//     min-width: 0px;
//     background: #fff;
//     z-index: 2000;
//     height: 100%;
//     padding-bottom: 1rem;
//     display: block;
//     opacity: 0;
//     transition: linear 0.3s;
//     pointer-events: none;
//     overflow-y: scroll;
//     .show{
//         opacity: 1;
//         top: 0;
//         min-width: 300px;
//         pointer-events: initial;
//     }
// }
// .s_search {
//     position: absolute;
//     left: 0;
//     top: 10px;
//     margin-right: 1rem;
// }
