.general {
    &_tabs {
        &-button {
            cursor: pointer;
            display: inline-flex;
            align-items: center;
            svg {
                fill: #fff;
            }
            &.general_tabs-button-active {
                svg {
                    fill: #000;
                    // fill: #fff;
                }
            }
        }
    }
    &_tab {
        // Temporarily disable opacity and visibility
        // opacity: 0;
        // visibility: hidden;
        display: none;
        &-boxContainer {
            display: grid;
            grid-template-columns: 1fr;
            // overflow-x: scroll;
            > .general_tab {
                grid-column: 1 / -1;
                grid-row: 1 / -1;
            }
        }
        &.general_tab-active {
            // Temporarily disable opacity and visibility
            // opacity: 1;
            // visibility: visible;
            display: block;
            .booking_check {
                z-index: 99;
            }
        }
    }
}
