.navbar-light .navbar-nav .nav-link-top {
    color: rgb(255, 255, 255) !important;
    padding-left: 1rem;
    padding-right: 1rem;
}

.nav {
    &-item {
        &-login,
        &-register {
            position: relative;
            padding-left: 1rem;
            transition: all 0.4s;
            &:not(:last-child){
            &:after {
                position: absolute;
                content: "";
                width: 1px;
                height: 80%;
                top: 50%;
                transform: translateY(-50%);
                right: 3px;
                background-color: lighten($color-grey, 50%);
                @include respond(col-md) {
                    display: none;
                }
            }
        }
            .nav-link {
                &:after {
                    position: absolute;
                    width: 1rem;
                    height: 1rem;
                    top: 50%;
                    left: 8px;
                    content: "";
                    display: inline-block;
                    background-color: #fff;
                    transform: translateY(-50%);
                }
            }
            &:hover {
                background-color: $color-primary-darker;
            }
            &:hover .nav-link-top {
                // color: $color-secondary;
                // background-color: $color-primary;
            }
            &:hover .nav-link:after {
                // background-color: $color-secondary;
            }
        }
        &-login {
            // background-color: $color-secondary;
            &-logged {
                padding-left: 0;
                .nav-link {
                    &:after {
                        display: none;
                    }
                }
                @media (max-width: 767px) {
                    border-top-right-radius: 5px;
                }
            }
            // To make image and text appear in a row
            .nav-link {
                display: flex;
                flex-direction: row;
                img {
                    margin-right: 0.5rem;
                }

            }

            &:hover .nav-link {
                color: #fff !important;
            }
            @media (max-width: 767px) {
                border-top-left-radius: 5px;
            }
            .nav-link {
                &:after {
                    mask-image: url(/img/svg/login.svg);
                    mask-size: cover;
                    -webkit-mask-image: url(/img/svg/login.svg);
                    -webkit-mask-size: cover;
                }
                &:hover &:after {
                    display: none;
                }
            }
        }
        &-register {
            // background-color: $color-primary-ticket;
            position: relative;
            .nav-link {
                &:after {
                    mask-image: url(/img/svg/how_to_reg.svg);
                    mask-size: cover;
                    -webkit-mask-image: url(/img/svg/how_to_reg.svg);
                    -webkit-mask-size: cover;
                }
            }

            // &:before {
            //     position: absolute;
            //     top: 0;
            //     left: 0;
            //     width: 1px;
            //     height: 100%;
            //     content: "";
            //     background-color: $color-primary-ticket;
            // }
            &:hover .nav-link {
                color: #fff !important;
            }
            @media (max-width: 767px) {
                border-top-right-radius: 5px;
            }
            // background-color: $color-primary;
        }
        // DROPDOWN MENU FOR NAVBAR CHILD LIST ITEMS
        &.dropdowns {
            position: relative;
            @media (max-width: 991px){
                &:hover .dropdown-menu{
                    max-height: 100%;
                    display: block;
                }
            }
            &:hover .dropdown-menu {
                opacity: 1;
                visibility: visible;
                // display: block;
            }
            .dropdown-menu {
                // position: absolute;
                padding: 0;
                display: block;
                border: none;
                opacity: 0;
                visibility: hidden;
                transition: all 0.4s;
                top: calc(100% + 0.4rem);
                // padding: 0 1rem ;
                // top:0;
                @media (max-width: 991px){
                    max-height: 100%;
                    display: none;
                    // position: relative;


                }
                // Hide child page in mobile navbar
                // &-mobile {
                //     @media (max-width: 991px){
                //         max-height: 100%;
                //         overflow: hidden;
                //     }
                // }

                // &-open {
                //     display: block;
                //     opacity: 1;
                //     visibility: visible;
                // }
            }

            .dropdown-ite {
                padding: 0;
                position: relative;
                .nav-link {
                    padding: 0.5rem 1.5rem;
                    &:hover {
                        background-color: $color-secondary;
                        color: #fff;
                    }
                }
             &:hover #sub-dropdown{
                display: block;
                // position: absolute;
                // top: -20px;
                // left: 100%;
             }
            }
        }
    }
    //.has-dropdown{
    //    position: relative;
    //    .dropdown-header{
    //        position: absolute;
    //        bottom: 0;
    //        left: 0;
    //        display: none;
    //    }
    //}
    &-link-sub{
        padding: .5rem;
    }
}

.navbar {
    padding: 0 1rem;
    background-color: $color-primary-darker;
    // &_container {
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     width: 100%;
    // }
    // navbar_contact
    &-nav {
        &.navbar-nav-logins {
            flex-direction: row;
        }
    }
    &-brand {
        padding: 0.5rem 0;
        img {
            width: auto;
            height: 51px;
        }
    }
    &_contact {
        display: flex;
        list-style: none;
        margin: 0;
        padding: 0;
        @include respond(col-md) {
            // flex-direction: column;
            padding: 8px 0;
        }
        &-list {
            color: #fff;
            display: flex;
            align-items: center;
            font-size: 0.75rem;
            position: relative;
            @include respond(col-md) {
                &:not(:last-child) {
                    // margin-bottom: 8px;
                }
            }
            // &:not(:last-child) {
            //     @include respond(col-md) {

            //     }
            // }
            svg {
                margin-right: 5px;
            }
            &:not(:last-child) {
                margin-right: 1rem;
                &:after {
                    position: absolute;
                    content: "";
                    width: 1px;
                    height: 80%;
                    top: 50%;
                    transform: translateY(-50%);
                    right: -10px;
                    background-color: lighten($color-grey, 50%);
                    @include respond(col-md) {
                        display: none;
                    }
                }
            }
        }
    }
}
.header-bottom {
    .navbar.bg-white {
        background-color: lighten($color-grey-light-1, 5%) !important;
        // background-color: transparent !important;
    }
    .navbar {
        .nav-item {
            position: relative;
            &-expand {
                position: absolute;
                top: 8px;
                right: 1rem;
                @media (min-width: 991px) {
                    display: none;
                }
                &-on {
                    .general_trigger-icon {
                        &:before {
                            opacity: 0;
                            visibility: hidden;
                        }

                        &:after {
                            transform: translateY(-50%) rotate(180deg);
                        }
                    }
                }
            }
        }
        .nav-link {
            font-family: $font-heading;
            text-transform: capitalize !important;
            &:hover {
                color: $color-red-prim;
                fill: $color-red-prim;
            }
            @media (max-width: 991px) {
                display: inline-block;
            }
            &-active{
                    color: $color-red-prim;
                    fill: $color-red-prim;
            }
        }
    }
    .navbar-collapse {
        justify-content: flex-end;
        .navbar-toggler {
            @media (max-width: 991px) {
                font-size: 1.5rem !important;
                // float: right;
            }
        }
        &.show {
            min-width: 200px;
        }
    }
}

// nav.navbar.bg-white {
//     background-color: transparent !important;
// }

// nav.navbar,
// header.site-header {
//     position: absolute;
//     width: 100%;
//     background-color: transparent;
//     top: 0;
//     left: 0;
// }

// header.site-header {
//     top: 55.6px;
// }

// .navbar-expand-md .navbar-nav .dropdown-menu {
//     position: absolute !important;
// }
#sub-dropdown{
    position: absolute;
    left: 100%;
    top: 0;
    display: none;
    li{
        background-color: #fff;
        // position: absolute;
        @include respond(tab-portrait){
            display: none;
        }

    }
    @media (max-width:991px){
        position: static;
    }
}
