body {
    font-size: 14px;
    // font-family: "Lato", sans-serif;
    // font-family: "Raleway", sans-serif;
    // font-family: "Roboto Condensed", sans-serif;
    font-family: $font-body;
    // background-color: $search-flight-background;
    -webkit-font-smoothing: antialiased;
}

p {
    line-height: 1.6;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
    top: 100%;
    left: 0;
    z-index: 3;
}

.header-bottom {
    background-color: lighten($color-grey-light-1, 5%);
}

ul.iconned img,
ul.iconned i {
    color: $color-secondary;
}

ul.iconned svg {
    position: absolute;

    left: 5px;
}

.form-inline .btn {
    background-color: #fff;
}

.contact-form {
    ul.iconned svg {
        top: 6px;
    }
}
.footer {
    background-color: $color-primary-darker;
    h2 {
        font-family: $font-heading;
        text-transform: uppercase;
    }
    ul.iconned {
        margin-top: 0;
        .iconned_li {
            svg {
                top: 0.4rem;
            }
        }
    }
    ul.iconned svg {
        fill: #fff;
        top: 0.5rem;
        // fill: lighten($color-secondary, 10%);
    }
}

// Overriding styles in tour detail page

.nav-tabs .active:after {
    background: $color-secondary;
}

// container widths override
.container {
    // @media (min-width: 576px) {
    //     max-width: 100%;
    // }

    // @media (min-width: 768px) {
    //     max-width: 50%;
    // }
}

// Overriding styles in home page
.services-section .container:after {
    display: none;
}

*{
    scroll-behavior: smooth;
}


input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}
.is-today{
    opacity: 40%;
}
// ul {
//     list-style-type: disc;
//     // display: block;
// }


