.content_home {
    .booking_tabs-container {
        display: grid;
        grid-template-columns: repeat(2, max-content);
        grid-gap: .1rem;
    }
}

.search_details-flight-baggage {
    .search_flight-details-box-tabs {
        padding: 1rem 3rem 0 3rem;

        @include respond(col-md) {
            padding: 1rem .3rem 0 .3rem;
        }
    }

    .booking_tabs-button {
        color: #fff;

        &.general_tabs-button-active {
            color: $color-font-dark;
        }

        &:hover {
            // color: #fff;
            // $color-font-dark;
        }
    }
}

.search_flight {
    // background-image: linear-gradient(
    //     to bottom right,
    //     lighten($color-secondary, 34%),
    //     lighten($color-secondary, 36%)
    // );
    background-color: lighten($color-secondary, 36%);
    background-color: #feeeda;
    background-color: lighten($color-grey-light-1, 5%);
    background-color: lighten($color-grey-light-1, 2%);
    background-color: #def0fe;
    background-color: $color-secondary-lighter;
    // background-color: #ecf7ff;
    // background-color: #f3faff;

    // background: url(/img/svg/asset.svg);
    // background-color: #fff9f0;
    // background-color: #fff;
    background-color: $color-grey-light-1;
    min-height: 100vh;
    padding: 2rem 0;

    &-class {
        font-size: 12px;
    }

    &-terms-box {
        display: flex;
        align-items: center;

        &-link {
            margin-left: 0.5rem;
        }

        label {
            margin-bottom: 0;
        }
    }

    &-refund,
    &-norefund {
        font-size: 12px;
    }

    &-refund {
        color: $color-green;
    }

    &-norefund {
        color: $color-red;
    }

    &-onward {
        font-weight: bold;
        margin: 0 5px;
    }

    &-general-tab {
        // padding: 1rem 5rem 1rem 4rem;
        border: 2px solid lighten($color-secondary, 15%);
        border: 2px solid $color-primary;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    &-baggage {
        &-tab {
            padding: 1rem 4rem;

            @include respond(col-md) {
                padding: 1rem 2rem;
            }

            .search_flight-details-box-tabs {
                position: relative;

                &:after {
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 1px;
                    background-color: lighten($color-secondary, 20%);
                    content: "";
                }
            }

            // display: grid;
            // grid-template-columns: repeat(3, max-content);
            // grid-gap: 1.5rem;
            // align-items: center;
            table td {
                vertical-align: middle !important;
            }

            table thead tr {
                border-bottom: 1px solid rgba($color-primary-dark, 0.2);
            }

            table tbody tr:nth-child(odd) {
                // background-color: lighten($color-secondary-lighter, 1%);
            }

            table tbody tr:not(:last-child) {
                border-bottom: 1px solid lighten($color-grey, 30%);
            }

            table td:first-child {
                display: flex;
                align-items: center;
            }

            &-l {
                display: flex;
                align-items: center;
            }

            .search_flight-airlines-logo {
                grid-template-columns: 1rem 1fr 1rem;
                margin: 0 0.5rem;
            }

            .search_flight-via {
                margin-left: 0.5rem;
            }
        }

        &-container {
            display: grid;
            grid-gap: 0.5rem;
            grid-template-columns: repeat(1, 1fr);
        }

        &-luggage,
        &-handCarry {
            position: relative;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }

            &:hover &-hover {
                opacity: 1;
                visibility: visible;
            }

            &-hover {
                opacity: 0;
                visibility: hidden;
                position: absolute;
                left: 0;
                transition: all 0.4s;
                width: max-content;
                padding: 2px 5px;
                border-radius: 2px;
                color: #fff;
                background-color: $color-primary-dark;

                &:after {
                    content: "";
                    position: absolute;
                    display: inline-block;
                    // border-top: 5px solid red;
                    border-right: 5px solid transparent;
                    border-left: 5px solid transparent;
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }

        &-luggage {
            &-hover {
                top: -150%;

                &:after {
                    border-top: 5px solid $color-primary-dark;
                    bottom: -5px;
                }
            }
        }

        &-handCarry {
            &-hover {
                bottom: -150%;

                &:after {
                    border-bottom: 5px solid $color-primary-dark;
                    top: -5px;
                }
            }
        }
    }

    &-book {
        grid-column: 2 / span 1;
        grid-row: 2 / span 1;
        // align-self: end;
        // justify-self: end;

        .button {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 0px;
            background-color: transparent;
            color: $color-font-dark;
            color: #fff;
            position: relative;
            z-index: 1;
            border-top-left-radius: 5px;

            &:after,
            &:before {
                position: absolute;
                content: "";
                top: 0;
                height: 100%;
                transition: all 0.4s;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
            }

            &:before {
                // width: 200%;
                // left: 200%;
                // background-color: $color-secondary;
                // z-index: -1;
            }

            &:after {
                width: 100%;
                left: 0;
                z-index: -2;
                background-color: $color-primary;
            }

            &:hover {
                &:before {
                    left: 0;
                }
            }
        }
    }

    // .search_flight-modal starts
    &-modal {

        // position: fixed;
        // top: 50%;
        // width: 60%;
        // height: 90%;
        // overflow-y: auto;
        // left: 50%;
        // transform: translate(-50%, -50%);
        // background-color: #fff;
        // z-index: 9999;
        // transition: all 0.5s;
        // padding: 3rem 5rem;
        // border-radius: 5px;
        // display: none;
        // background-color: lighten($color-secondary, 37%);
        // .form-group {
        //     margin-bottom: 2rem;
        // }
        // hr {
        //     margin-bottom: 2rem;
        // }
        .form-group {
            >div:not(:last-child) {
                .booking_form-fields-col {
                    @media (max-width: 991px) {
                        margin-bottom: 2rem;
                    }

                    // @include respond(col-md) {
                    //     margin-bottom: 2rem;
                    // }
                }

                .counter_container {
                    @media (max-width: 991px) {
                        margin-bottom: 2rem;
                        margin-top: 0;
                    }
                }
            }
        }

        &-personalRow {
            margin-bottom: 2rem;
        }

        &-passportRow {
            padding-bottom: 1.5rem;
        }

        &-gender {
            display: flex;
            flex-direction: column;

            label {
                margin-bottom: 0;
                display: flex;
                align-items: center;

                input {
                    margin-right: 5px;
                }
            }

            span {
                display: flex;
                align-items: center;
            }
        }

        &-active {
            display: block;
        }

        &-col {
            label {
                background-color: lighten($color-secondary, 37%);
            }
        }

        &-input {
            background-color: lighten($color-secondary, 37%);
        }

        .booking_form-fields-col {
            label {

                &:before,
                &:after {
                    background-color: lighten($color-secondary, 37%);
                }
            }

            label,
            input,
            select {
                background-color: #fff;
            }

            input:-webkit-autofill,
            input:-webkit-autofill:hover,
            input:-webkit-autofill:focus {
                background-color: #fff !important;
                // border: 1px solid green;
                // -webkit-text-fill-color: green;
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
                // transition: background-color 5000s ease-in-out 0s;
            }

            select:-webkit-autofill,
            select:-webkit-autofill:hover,
            select:-webkit-autofill:focus {
                background-color: #fff !important;
                -webkit-box-shadow: 0 0 0px 1000px #fff inset;
            }
        }
    }

    // .search_flight-modal ends
    &-layover {
        display: grid;
        grid-template-columns: repeat(2, max-content) 1fr;
        justify-content: center;
        align-items: center;
        padding: 5px 16px;
        border-radius: 2px;
        grid-column-gap: 5px;
        // grid-gap: 0.5rem;
        grid-column: 1 / -1;

        span {
            display: inline-flex;
            align-items: center;
            font-weight: bold;

            svg {
                margin-right: 5px;
            }
        }

        &:after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 1px;
            background-color: #ebf3f9;
            background-color: lighten($color-grey, 30%);
            grid-column: 2 / -1;
        }

        // span {
        //     grid-column: 2 / span 1;
        //     font-weight: bold;
        // }
        // &:before,
        // &:after {
        //     content: "";
        //     display: inline-block;
        //     width: 100%;
        //     height: 1px;
        //     background-color: $color-grey-light;
        // }
        // &:before {
        //     grid-column: 1 / span 1;
        // }
        // &:after {
        //     grid-column: 3 / span 1;
        // }
    }

    // search_flight-top starts
    &-top {
        background-color: darken($color-primary, 15%);
        background-color: lighten($color-secondary, 36%);
        background-color: lighten($color-grey-light-1, 2%);
        background-color: $color-font-dark-1;
        // background-color: $color-primary;
        padding: 1.5rem 0;

        &-dom {
            .general_tab {
                display: block;
                opacity: 1;
                visibility: visible;
            }
        }

        .multi_flight label {
            color: $color-primary-dark;
        }

        .multi_add-flight {
            // color: $color-primary-dark;
        }

        .multi_flight .svg_white {
            fill: $color-primary-dark;
        }

        &-loader {
            .loader_msg-message {
                color: #fff;
            }

            background-color: $color-font-dark-1;

            .loader_msg {
                background-color: transparent;
            }

            #loader_path {
                fill: #fff;
            }

            .loader_msg-txt-noback {
                color: #fff;
            }
        }

        .booking_form-fields-col label:before {
            // background-color: $search-flight-background;
        }

        .search_flight-summ-details-country {
            span:first-child {
                text-transform: unset;
            }
        }

        .search_flight-summ-summary {
            span {
                color: #fff;

                &.search_flight-onward {
                    color: lighten($color-grey, 15%);
                }
            }
        }

        .search_flight-summ-details-country {
            flex-direction: column;
            align-items: start;
        }

        .booking_tabs-button {
            // color: $color-font-dark;
            color: #fff;
        }

        &-forms {
            margin: 2rem 0;

            .booking_forms-wrapper,
            .booking_form-fields-code {
                background-color: #fff;
            }

            .booking_form-fields-code {
                background-color: #fff;
            }

            .booking_form-fields-col {

                input,
                label {
                    background-color: #fff;
                }
            }

            .booking_form-fields-input,
            .booking_form-fields-select {

                // border: 1px solid lighten($color-grey, 30%);
                &:hover {
                    // border: 1px solid $color-secondary;
                }

                &:focus {
                    outline: none;
                    // border: 1px solid $color-secondary;
                }
            }

            .counter_actions {
                border: 1px solid lighten($color-grey, 30%);

                &:hover {
                    border: 1px solid $color-secondary;
                }
            }

            .counter_minus {
                border-right: 1px solid lighten($color-grey, 30%);
            }

            .counter_plus {
                border-left: 1px solid lighten($color-grey, 30%);
            }

            // .counter_container {
            //     select {
            //         background-color: $search-flight-background;
            //     }
            // }
            .counter_actions {
                background-color: #fff;
            }
        }
    }

    // search_flight-top ends
    &-details-container {
        position: relative;

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: -20px;
            width: 1px;
            height: 100%;
            background-color: $color-grey-light-1;

            @include respond(small-desktop) {
                display: none;
            }
        }
    }

    &-info-container,
    &-details-container {
        display: grid;
        grid-row-gap: 0.1rem;
        justify-self: start;

    }

    &-info-container {
        grid-template-columns: 1fr;
        grid-row-gap: 1.5rem;
        // padding-left: 1rem;
        justify-self: start;
    }

    &-details-container {

        // grid-template-columns: repeat(3, max-content);
        @include respond(tab-portrait) {
            // grid-row: 2 / span 1;
        }
    }

    &-results {
        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
        grid-row-gap: 2.5rem;

        .heading_4 {
            color: #0c4da1;
        }

        .section-header.line-left {
            margin-bottom: 0;
            padding-left: 0;
            display: flex;
            align-items: center;

            svg {
                margin-right: 5px;
            }

            &:before {
                display: none;
            }

            @include respond(tab-landscape) {
                font-size: 1.25rem;
            }

            @include respond(phone) {
                font-size: 1rem;
            }
        }

        &-refund {
            position: absolute;
            right: 6rem;
            top: 0;
            // grid-column: 3 / span 1;
            // grid-row: 2 / span 1;
            height: 40px;
            line-height: 40px;
            width: max-content;
            padding: 0 1rem;
            // background-color: $color-primary-dark;
            // color: #fff;
            border-radius: 2px;
            border: 2px solid $search-flight-background;
        }

        &-box {
            // display: grid;
            // grid-template-columns: 1fr repeat(4, min-content);

            overflow: hidden;

            >div:first-child {
                position: relative;
                padding: 2rem 2rem 0 2rem;
                display: grid;
                // grid-template-columns: repeat(3, max-content);
                grid-template-columns: 70fr 30fr;
                // grid-template-columns: 70fr 10fr;
                // grid-template-rows: repeat(2, min-content);
                grid-auto-rows: min-content;
                grid-row-gap: .3rem;
                grid-column-gap: 1rem;
                justify-content: space-between;
                align-items: center;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    height: 2px;
                    width: 100%;
                    background-color: $color-primary;
                }

                &:hover {
                    cursor: pointer;
                }

                @include respond(tab-portrait) {
                    grid-row-gap: .3rem;
                }
            }

            // display: flex;
            // justify-content: space-between;
            // align-items: center;
            // grid-column-gap: 1rem;
            // padding: 3rem 6rem;
            background-color: lighten($color-secondary, 37%);
            // background-color: lighten($color-grey-light-1, 5%);
            // background-color: #fff;
            // border: 2px solid #def0fe;
            transition: all 0.4s;
            // background-color: #fff9f0;
            background-color: #fff;
            border-radius: 5px;
            // border: 1px solid lighten($color-grey-light-1, 5%);
            box-shadow: 1px 1px 5px rgba($color-grey-light-1, 0.8);

            &:hover {
                // background-color: lighten($color-secondary, 36%);
            }

            &:hover .svg_translate {
                transform: translateX(100%) rotate(90deg);
                opacity: 0;
                visibility: hidden;
            }

            &:hover .search_flight-airlines-svg-abs {
                left: 0;
                opacity: 1;
                visibility: visible;
                // fill: lighten($color-secondary, 24%);
            }
        }
    }

    // Search flight filter
    &-filter {
        background-color: #fff;
        max-height: 0;
        overflow: hidden;
        transition: all 0.4s;

        &-button {
            display: flex;
            align-items: center;

            @include respond(small-desktop) {
                justify-self: start;
            }

            svg {
                margin-right: 5px;
            }
        }

        &-active {
            padding: 2rem 0;
        }

        &-header {
            border-bottom: 1px solid rgba($color-primary-dark, 0.2);
            padding: 0.5rem 1rem;
        }

        &-info {
            span {
                font-size: 12px;
            }
        }

        label {
            margin-bottom: 0;
        }

        // background-color: $color-primary-dark;
        &-box {
            // padding: 12px 8px;
            // border-right: 1px solid $color-grey-light;
            position: relative;
            z-index: 9999;
            // border: 1px solid rgba($color-primary-dark, 0.3);
            // border: 1px solid #defef0;
            border-radius: 2px;

            &-title {
                // color: $color-secondary;
                // background-color: $color-primary-dark;
                // border: 1px solid rgba($color-grey, 0.4);
                font-weight: bold;
            }

            &-select {
                &-range {
                    div {
                        // display: grid;
                        // grid-template-columns: max-content 1fr max-content;
                        // grid-column-gap: 0.5rem;
                    }

                    display: flex;
                    flex-direction: column;
                }

                // position: absolute;
                // left: 0;
                // top: 100%;
                min-width: 100%;
                padding: 1rem;
                border-top: 1px solid $color-grey-light;
                background-color: lighten($color-secondary, 90%);
                // box-shadow: 2px 2px 5px rgba($color-primary-dark, 0.2);
                border-bottom-left-radius: 2px;
                border-bottom-right-radius: 2px;

                input[type="checkbox"] {
                    display: none;
                    accent-color: $color-secondary;
                }
            }
        }

        label.search_flight-filter-input {
            &:not(:last-child) {
                margin-bottom: 0.5rem;
            }
        }

        &-input {
            &:hover &-checkmark {
                opacity: 0.2;
                visibility: visible;
            }

            display: flex;
            align-items: center;

            input:checked+.search_flight-filter-input-checkbox svg {
                opacity: 1;
                visibility: visible;
            }

            &-checkmark {
                opacity: 0;
                visibility: hidden;
            }

            &-checkbox {
                width: 24px;
                height: 24px;
                display: inline-flex;
                justify-content: center;
                align-items: center;
                border-radius: 2px;
                border: 1px solid $color-grey;
                margin-right: 5px;
            }

            label {
                margin-bottom: 0;
            }
        }
    }

    &-price {
        font-weight: bold;
        // position: relative;
        // padding: 0.5rem;
        // background-color: $color-secondary;
        // border-top-left-radius: 5px;
        // border-bottom-left-radius: 5px;
        // &:after {
        //     content: "";
        //     position: absolute;
        //     width: 50%;
        //     height: 100%;
        //     right: 0;
        //     transform: translateX(100%);
        //     background-color: $color-secondary;
        // }
    }

    &-info {
        display: grid;
        grid-template-columns: repeat(4, max-content);

        @include respond(small-desktop) {
            // grid-template-columns: repeat(4, minmax(max-content, 1fr));
        }

        grid-gap: 1.5rem;
    }

    &-country {
        @include flex_justify-center;
        justify-content: center;
        flex-direction: column;

        // align-items: center;
        &-name {
            // color: lighten($color-secondary, 15%);
            // color: #536270;
        }

        &-time {
            color: $color-grey;
            color: $color-font-dark;
            font-size: 12px;
            font-size: 1rem;
            font-weight: bold;
        }
    }

    &-airlines {
        @include flex_justify-center;
        flex-direction: column;
        align-items: center;

        &-svg {
            fill: lighten($color-grey, 15%);
            fill: $color-primary-light-30;
            // fill: $color-font-dark;

            grid-column: 2 / span 1;
            transition: all 0.4s;

            &-abs {
                position: absolute;
                top: 0;
                left: -100%;
                opacity: 0;
                visibility: hidden;
                fill: lighten($color-secondary, 15%);
                fill: $color-primary-light;
            }
        }

        &-logo {
            position: relative;

            &:before,
            &:after {
                content: "";
                height: 1px;
                background-color: lighten($color-grey, 30%);
                background-color: #f3faff;
                background-color: #def0fe;
                width: 100%;
            }

            &:before {
                grid-column: 1 / span 1;
            }

            &:after {
                grid-column: 3 / span 1;
            }

            display: grid;
            align-items: center;
            grid-column-gap: 5px;
            grid-template-columns: 64px 1fr 64px;

            @include respond(phone) {
                grid-template-columns: 32px 1fr 32px;
            }

            &.airlines-logo-top {
                grid-template-columns: 1rem 1fr 1rem;

                @include respond(phone) {
                    grid-template-columns: 1rem max-content 1rem;
                    justify-self: start;
                }

                .search_flight-airlines-svg {
                    fill: $search-flight-background;
                    fill: lighten($color-grey, 15%);
                }
            }

            &-svg {
                position: absolute;
                top: -12px;
                left: 50%;
                fill: lighten($color-grey, 15%);

                transform: translateX(-50%);

                &.bottom-svg {
                    bottom: -12px;
                    top: unset;
                    left: 50%;
                    // fill: lighten($color-grey, 15%);
                    transform: translateX(-50%) rotate(180deg);
                }
            }
        }

        &-checkpoints {
            display: flex;
            align-items: center;

            svg {
                margin: 0 5px;
            }
        }

        &-duration,
        &-checkpoints {
            color: $color-grey;
            font-size: 12px;
        }
    }

    &-via {
        // @include flex_justify-center;
        // display: flex;
        flex-direction: column;
        justify-content: center;

        @include respond(small-desktop) {
            display: grid;
            //    grid-row: 2 / span 1;
            grid-template-columns: repeat(2, 1fr);
            flex-direction: row;
            align-items: center;
            //    grid-column: 1 / span 2;
            //    grid-column: 1/3;
            //    flex-direction: row;
            //    justify-content: start;
        }

        &-text {
            // font-size: 2rem;
            margin-right: 0.5rem;
        }

        &-airlines {
            width: 60px;
            // height: 30px;
            overflow: hidden;
            display: flex;
            align-items: center;
            margin-right: 0.5rem;
            margin-bottom: .5rem;

            @include respond(tab-portrait) {
                width: 50px;
                margin-bottom: 0rem;
            }

            img {
                width: 100%;
                height: auto;
            }
        }

        &-name {
            font-size: 14px;
            @include respond(tab-portrait) {
                font-size: 10px;
            }
        }
    }

    &-details {
        cursor: pointer;
        justify-self: start;
        // padding: 0 1rem;
        background-color: lighten($color-grey-light-1, 2%);
        background-color: #f3faff;
        background-color: transparent;
        border-top-right-radius: 5px;
        // color: $color-font-dark;
        color: #fff;
        color: $color-font-dark;
        // color: lighten($color-primary-light, 15%);
        transition: all 0.4s;
        // transform: translateY(100%);
        position: relative;
        z-index: 1;
        grid-row: 2 / span 1;
        grid-column: 1 / span 2;

        // svg {
        //     position: absolute;
        //     right: 0;
        //     top: 0;
        // }
        &-box {
            &-tabs {
                // display: inline-flex;
                padding: 0;

                // margin: 0 1rem 0.5rem 1rem;
                // background-color: $color-secondary;
                // border-bottom: 1px solid $color-secondary;
                &:after {
                    content: "";
                }

                .general_tabs-button-active {
                    border-bottom: none;
                }
            }
        }

        &:before,
        &:after {
            // content: "";
            position: absolute;
            top: 0;
            height: 100%;
            border-top-right-radius: 5px;
        }

        &:before {
            width: 0;
            left: -100%;
            background-color: $color-secondary;
            transition: all 0.4s;
            z-index: -1;
        }

        &:after {
            width: 200%;
            right: 0;
            z-index: -2;
            background-color: $color-primary;
            background-color: $color-font-dark-1;
            background-color: lighten($color-secondary, 37%);
            border: 2px solid #def0fe;
        }

        &:hover {
            color: #fff;

            // background-color: red;
            // background-color: $color-primary-light;
            &:before {
                width: 200%;
            }
        }

        // search_flight-details-baggage
        &-baggage {
            display: flex;
            flex-direction: column;

            &-title {
                font-weight: bold;
                margin-bottom: 0.5rem;
            }

            &-value {
                white-space: nowrap;

                &:not(:last-child) {
                    margin-bottom: 4px;
                }
            }
        }

        &-button {
            padding: 0.5rem;

            &.general_tabs-button-active {
                color: #fff;
                // background-color: $color-secondary;
            }
        }

        &-header {
            padding: 1rem 4rem;
            border-bottom: 1px solid lighten($color-grey, 30%);
            border-bottom: 1px solid rgba($color-primary-dark, 0.2);
            background-color: #f0f0f0;
            background-color: lighten($color-secondary-lighter, 2%);
            background-color: $color-grey-light-1;
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            @include respond(tab-landscape) {
                display: grid;
                grid-gap: 5px;
            }

            @include respond(col-md) {
                padding: 1rem 2rem;
            }

            // border-bottom: 2px solid #5096c8;
            // border-bottom: 1px solid lighten($color-secondary, 30%);

            span {
                margin-right: 1rem;
                // color: #fff;
            }

            &-title {
                // max-width: 540px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: inline-flex;
                align-items: center;

                @include respond(col-md) {
                    display: flex;
                    flex-direction: column;
                    align-items: flex-start;
                }

                span {
                    margin-right: 0;
                    display: inline-flex;
                    align-items: center;
                    @include respond(col-md) {
                       flex-wrap: wrap;
                    }

                    &:last-child {
                        margin-left: 5px;
                    }
                }
            }

            svg {
                fill: $color-primary-light;
                // fill: #fff;
            }

            &-date {
                // color: #fff;
                font-weight: bold;

                span:last-child {
                    margin-right: 0;
                }
            }

            &-title {

                // color: #fff;
                .title-code {
                    font-weight: bold;
                }

                svg {
                    margin-right: 5px;
                }
            }
        }

        &-box {
            max-height: 0;
            overflow: hidden;
            transition: all 0.4s;
            // margin-top: 1rem;
        }

        &-tabs {

            // border-bottom: 1px solid $color-grey-light;
            // padding: 0 1rem;
            // border-bottom: 1px solid lighten($color-secondary, 30%);
            .search_flight-airlines-svg {
                fill: $color-font-dark;
                display: none;

                @include respond(col-md) {
                    display: block;
                }
            }

            .general_tabs-button {
                color: #fff;
                // background-color: lighten($color-secondary, 30%);
                // background-color: #fff;
                border-top-left-radius: 2px;
                border-top-right-radius: 2px;
                border-left: 2px solid lighten($color-secondary, 10%);
                border-right: 2px solid lighten($color-secondary, 10%);
                border-top: 2px solid lighten($color-secondary, 10%);
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;

                &:not(:last-child) {
                    margin-right: .3rem;
                }

                @include tabs_button;
                color: $color-font-dark;
                padding-left: 10px;
                padding-right: 10px;

                @include respond(verysmall-screen) {
                    padding-left: 7px;
                    padding-right: 7px;
                }


                // border-bottom: 1px solid $color-secondary;
                margin-bottom: 2px;

                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    transform: translateY(4px);
                    height: 6px;
                    width: 100%;
                    // background-color: $color-primary;
                    // background-color: $color-secondary-lighter;
                    // transition: all 0.4s;
                }

                &.general_tabs-button-active {
                    // color: #fff;
                    // color: $color-secondary;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    border-bottom: none;
                    border-left: 2px solid $color-primary;
                    border-right: 2px solid $color-primary;
                    border-top: 2px solid $color-primary;
                    background-color: $color-grey-light-1;

                    &:after {
                        background-color: #fff;
                        background-color: $color-grey-light-1;
                    }
                }

                // transition: all 0.4s;
                &:hover {
                    border-left: 2px solid $color-primary;
                    border-right: 2px solid $color-primary;
                    border-top: 2px solid $color-primary;
                }

                &:hover .search_flight-airlines-svg {
                    // fill: $color-secondary;
                }

                >span {
                    @include respond(col-md) {
                        display: none;
                    }
                }
            }
        }

        &-content {
            padding: 1rem 2rem;

            table thead tr {
                border-bottom: 1px solid rgba($color-primary-dark, 0.2);
            }

            .table-trBorder tbody tr:last-child {
                border-top: 1px solid lighten($color-grey, 30%);
            }

            &-grid {
                display: grid;
                grid-template-columns: 1fr;
                grid-gap: 1rem;
            }

            &-l {}

            &-r {}

            &-baggage {
                display: flex;
                justify-content: space-evenly;
            }

            &-box {
                display: grid;
                grid-template-columns: min-content 1fr;
                grid-template-rows: repeat(4, min-content);
                grid-gap: 1rem;

                &-icon {
                    grid-row: 1 / span 3;
                    grid-column: 1 / span 1;
                    width: 1rem;
                    display: grid;
                    grid-template-rows: min-content 1fr min-content;
                    // grid-row-gap: 5px;
                    grid-template-columns: max-content;
                    justify-content: center;
                    position: relative;
                    padding: 0.5rem 0;

                    .search_flight-line {
                        content: "";
                        width: 1.5px;
                        height: 100%;
                        background-color: lighten($color-primary-light, 20%);
                        grid-row: 2 / span 1;
                        justify-self: center;
                    }

                    .search_flight-airlines-svg {
                        grid-row: 2 / span 1;
                        grid-column: unset;
                        fill: $color-primary-light;
                        transform: rotate(180deg);
                    }

                    &:before,
                    &:after {
                        content: "";
                        width: 0.5rem;
                        height: 0.5rem;
                        border-radius: 50%;
                        background-color: $color-primary-light;
                        justify-self: center;
                    }

                    &:before {
                        grid-row: 1 / span 1;
                    }

                    &:after {
                        grid-row: 3 / span 1;
                    }
                }

                &-origin,
                &-time,
                &-dest,
                &-info {
                    grid-column: 2 / -1;
                }

                &-origin {
                    grid-row: 1 / span 1;
                }

                &-time {
                    grid-row: 2 / span 1;
                    display: flex;
                    align-items: center;
                    font-weight: 700;

                    svg {
                        margin-right: 5px;
                    }
                }

                &-dest {
                    grid-row: 3 / span 1;
                }

                &-info {
                    grid-row: 4 / span 1;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }

            &-row {
                padding: 1rem 0;
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid lighten($color-secondary, 30%);
                border-bottom: 1px solid $color-grey-light;

                &-layover {
                    border-bottom: none;
                }
            }
        }
    }

    &-summ {
        display: flex;

        @include respond(tab-portrait) {
            // justify-content: center;
        }

        &-details {
            display: grid;
            grid-template-columns: repeat(3, max-content);
            grid-column-gap: 1rem;
            align-items: center;

            @include respond(phone) {
                grid-template-columns: 1fr;
                grid-gap: 0.5rem;
            }

            &-modify {
                @include respond(small-desktop) {
                    justify-self: start;
                }
            }

            &-country {
                display: flex;
                // flex-direction: column;
                align-items: center;

                @include respond(col-md) {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 6px;
                }

                span:first-child {
                    font-size: 1rem;
                    font-weight: bold;
                    text-transform: uppercase;
                    // margin-right: 5px;
                }

                &-name {
                    color: #fff;
                    color: $color-grey;
                    color: $color-font-dark;

                    span:last-child {}
                }
            }

            &-time {
                display: grid;
                // grid-template-columns: repeat(4, max-content);
                grid-auto-columns: max-content;
                grid-gap: 2px;
                align-items: center;

                @include respond(col-md) {
                    grid-auto-columns: unset;
                }

                // margin-left: 1rem;
                >span {
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;

                    svg {
                        margin-right: 5px;
                    }
                }

                svg {
                    fill: $color-grey;
                    // fill: $color-primary-light;
                    // fill: $color-secondary;
                }
            }

            &-airport {
                font-size: 12px;
                color: lighten($color-primary, 2%);
                color: $color-grey-light;
                color: $color-font-dark;
            }
        }

        &-summary {
            // display: grid;
            // grid-template-columns: repeat(2, max-content);
            display: grid;
            grid-template-columns: repeat(4, max-content);
            grid-gap: 3rem;

            @include respond(stab-landscape-submax) {
                grid-template-columns: 1fr;
                grid-auto-rows: min-content;
                grid-gap: 1rem;
            }

            // padding-bottom: 1.5rem;
            position: relative;
            align-items: center;

            &:after {
                position: absolute;
                bottom: 0;
                width: 100%;
                height: 1rem;
                // content: "";
                left: 0;
                background-color: lighten($color-secondary, 36%);
            }

            // svg {
            //     grid-column: 1 / -1;
            // }
        }

        &-div {
            margin-left: 1rem;

            svg {
                fill: lighten($color-grey, 15%);
            }

            span {
                vertical-align: middle;
            }
        }

        &-from {
            position: relative;

            &:after {
                content: "";
                height: 1px;
                width: 24px;
                background-color: lighten($color-grey, 15%);
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                left: calc(100% + 4px);
            }

            margin-right: 32px;
        }
    }

    &-circle {
        display: inline-block;
        width: 5px;
        height: 5px;
        border-radius: 50%;
        background-color: $color-font-dark;
        margin: 0 5px;
    }
}

.flightSearch_detail-active {
    background-color: $color-secondary;
    background-color: $color-primary-light;
    color: #fff;

    &:before {
        width: 200%;
    }
}

.flightSearch_panel-active {
    // margin-top: 0.5rem;
    border-top: 1px solid lighten($color-secondary, 25%);
    border-top: 1px solid $color-primary-light-35;
    padding: 1rem 0;
    // background-color: $color-grey-light-1;
}

.modify_form {
    max-height: 0;
    overflow: hidden;
    // overflow-y: visible;
    transition: all 0.4s;
}

.search_flight-details-tabs .general_tabs-button:hover:after {
    // background-color: #fff;
}

.search_details-flight-baggage {
    // overflow-x: scroll;
}

// All media queries for international search results starts

.search_flight-results-box-int {
    &.search_flight {
        &-results-box {
            >div:first-child {
                @include respond(col-md) {
                    padding: 1rem 1rem 0 1rem;
                }
            }
        }
    }

    .search_flight {
        &-class {
            @include respond(small-desktop) {
                grid-column: 1 / span 1;
                grid-row: 2 / span 1;
            }

            @include respond(tab-portrait) {
                grid-column: 3 / span 1;
                grid-row: 1 / span 1;
            }
        }

        &-refund {
            @include respond(small-desktop) {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
            }

            @include respond(tab-portrait) {
                grid-column: 2 / span 1;
                grid-row: 1 / span 1;
            }
        }

        &-norefund {
            @include respond(small-desktop) {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
            }

            @include respond(tab-portrait) {
                grid-column: 2 / span 1;
                grid-row: 1 / span 1;
            }
        }

        &-general-tab {
            @include respond(tab-landscape) {
                // padding: 1rem 3rem;
            }

            @include respond(col-md) {
                // padding: 1rem 2rem;
            }
        }

        &-baggage-container {
            @include respond(balance-desktop) {
                grid-column: 1 / -1;
                grid-row: 2 / span 1;
                justify-self: start;
            }
        }

        &-book {
            @include respond(balance-desktop) {
                grid-row: 5 / span 1;
                justify-self: start;
                grid-column: -1 / -1;
            }

            @include respond(tab-portrait) {
                grid-row: 5 / span 1;
                grid-column: 2/-1;
            }

            .button {
                @include respond(small-desktop) {
                    background-color: $color-primary;
                    border-radius: 5px;
                }

                &:before,
                &:after {
                    @include respond(small-desktop) {
                        height: 0;
                        width: 0;
                    }
                }
            }
        }

        &-info-container,
        &-details-container {
            @include respond(balance-desktop) {
                grid-column: -1 / -1;
                grid-row: 1 / span 1;
                // grid-row: 2 / span 1;
                justify-self: start;
                // grid-template-columns: repeat(3, max-content);
                grid-template-rows: min-content;
                grid-column-gap: 0.5rem;
                align-items: center;
            }

            @include respond(tab-landscape) {
                grid-column: -1 / -1;
                grid-row: 1 / span 1;
                // grid-row: 2 / span 1;
                justify-self: start;
                // grid-template-columns: repeat(3, max-content);
                grid-template-rows: min-content;
                grid-column-gap: 0.5rem;
                align-items: center;
            }

            @include respond(tab-portrait) {
                grid-column: 1 / -1;
                grid-row: 3 / span 1;
                // grid-row: 2 / span 1;
                justify-self: start;
                // grid-template-columns: repeat(3, max-content);
                grid-template-rows: min-content;
                grid-column-gap: 0.5rem;
                align-items: center;
            }

        }

        &-info-container {
            @include respond(balance-desktop) {
                grid-column: 1 / -1;
                grid-row: 1 / span 1;
                padding-left: 0;
            }
        }

        &-results {
            &-box {
                @include respond(small-desktop) {
                    padding: 2rem 3rem 0 3rem;
                }

                @include respond(tab-portrait) {
                    padding: 2rem 1rem 0 1rem;
                }
            }
        }

        &-price {
            @include respond(small-desktop) {
                grid-column: 1 / span 1;
                grid-row: 3 / span 1;
            }

            @include respond(tab-portrait) {
                grid-column: 1 / span 1;
                grid-row: 1 / span 1;
            }
        }

        &-info {
            @include respond(tab-portrait) {
                grid-template-columns: repeat(3, max-content);
                grid-template-rows: repeat(2, min-content);
                grid-gap: .5rem;
            }

            .search_flight-via {
                @include respond(tab-portrait) {
                    grid-column: 1 / -1;
                    // grid-row: 2 / -1;
                    justify-self: start;
                }
            }
        }

        &-details {
            @include respond(balance-desktop) {
                grid-row: 5 / span 1;
                padding-left: 0;
                font-size: 14px;
            }

            @include respond(tab-landscape) {
                // grid-row: 3 / span 1;
                grid-row: 5 / span 1;
                grid-column: 1 / -1;
            }
        }
    }
}
