.content_home {
    position: relative;
}

.loader {
    position: absolute;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    z-index: 999999;
    background-color: #fff;
    transition: all 0.4s;
    // padding-top: 2rem;

    display: flex;
    //  subik  loader previous
    // flex-direction: column;
    // align-items: center;
    //  ankit  loader previous
    flex-direction: column;
    align-items: center;
    @include respond(semi-desktop){
        flex-direction: column;
    }
    &-active {
        opacity: 1;
        visibility: visible;
    }

    opacity: 0;
    visibility: hidden;
    &_top {
        display: flex;
        flex-direction: column;
        align-items: center;
        // width: 100%;
       //  subik  loader previous
        // flex: 1 0 40%;
         //  ankit edit loader after
         flex: 1 0 30%;
        .loader_msg {
            margin-top: auto;
        }
    }

    &_bottom {
        flex: 1 0 70%;
        padding: 1rem 0;
        .img{
            .mobile-hide{
                display: block !important;
                @include respond(phone-landscape){
                    display: none !important;
                }
            }
            .mobile-show{
                display: none!important;
                    @include respond(phone-landscape){
                        display: block !important;
                    }
                }
        }
    }

    &_msg {
         //subik//
        // position: absolute;
        top: 290px;
        top: 65% ;
        top: 40%;
        left: 50%;
        //subik//
        // transform: translate(-50%, 100%);
        // transform: translateX(-50%);
        // width: 100%;
        text-align: center;
        background-color: #fff;
        &-txt {
            font-weight: 400;
            letter-spacing: -0.5px;
            word-spacing: 2px;
            // display: inline-block;
            text-align: center;
            position: relative;
            margin-bottom: 0;
            &-back {
                display: inline-block;
                background-color: $color-secondary;
                color: #fff;
                padding: 8px 1rem;
                font-size: 1.5rem;
            }
            &-noback {
                padding: 5px 1rem;
                font-size: 1.2rem;
                display: inline-block;
            }
            &:after {
                position: absolute;
                bottom: 0;
                content: "";
                height: 2px;
                border-radius: 50px;
                width: 100%;
                background-color: $color-secondary;
                left: 0;
            }
        }
        p:first-of-type {
            margin-top: 2rem;
        }
        &-message {
            // margin-top: 1rem;
            margin-bottom: 0.5rem;
            position: relative;
            padding: 0 2rem;
            text-align: left;
            font-size: 1rem;
            font-weight: 400;
            svg {
                position: absolute;
                top: 6px;
                left: 0.5rem;
            }
        }
    }
    &_svg {
        margin-top: -40%;
        width: 6rem;
        height: 6rem;
        // position: absolute;
        // top: 160px;
        // top: 45%;
        // top: 20%;
        // left: 50%;
        // transform: translate(-50%, -100%) rotate(-45deg);
        transform: translateY(100%) rotate(-45deg);
        fill: $color-primary;
        opacity: 0;
        visibility: hidden;
    }
    &_cls-1,
    &_cls-2,
    &_cls-3 {
        stroke: $color-secondary;
        stroke-linecap: round;
        opacity: 0;
    }
    &_cls-1 {
        stroke-width: 5px;
        stroke-dasharray: 33.94112px;
        stroke-dashoffset: 33.94112px;
    }
    &_cls-2 {
        stroke-width: 3.75px;
        stroke-dasharray: 25.4558px;
        stroke-dashoffset: 25.4558px;
    }
    &_cls-3 {
        stroke-width: 2.5px;
        stroke-dasharray: 16.97056px;
        stroke-dashoffset: 16.97056px;
    }
    &_path {
        &-1 {
            stroke: $color-secondary;
        }

        &-2 {
            stroke: $color-secondary;
        }
    }
}

#loader {
    &_line {
        &-4,
        &-5,
        &-6 {
            transform: rotate(3deg) translateY(-2px);
        }
    }
}

.ticket_loader {
    background-color: #fffdfa;
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    display: none;

    &_msg {
        margin-top: 1rem;
    }
    &_overlay {
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        height: 100%;
        z-index: 90;
        display: none;
        &_active {
            display: block;
        }
    }
    &_active {
        display: flex;
    }
    #rect1 {
        stroke-dasharray: 362px;
        stroke-dashoffset: 362px;
    }

    #Line_5 {
        stroke-dasharray: 59.5px;
        stroke-dashoffset: 59.5px;
    }

    #Line_6,
    #Line_8,
    #Line_9 {
        stroke-dasharray: 47px;
        stroke-dashoffset: 47px;
    }

    #Line_7 {
        stroke-dasharray: 35px;
        stroke-dashoffset: 35px;
    }

    #airplane {
        visibility: hidden;
    }
}
