// *{
//     font-family: "Inter", sans-serif;
// }

.content{
    .bg-section-holiday{
        padding: 2.5rem 0;
	    position: relative;
        z-index:1;
        // font-family: San Francisco Display Bold,sans-serif;

        &::before{
            content: '';
            position: absolute;
            // @include content-before-after;
            background: linear-gradient(to bottom, transparent , white );
            // opacity:0.89;
            height:50%;
            width:100%;
            left: 0;
            top:50%;
            pointer-events: none;
            // z-index:-1;
        }
        .cap_banner{
            font-size: 32px;
            font-weight: 700;
            // @include respond (phone){
            //     font-size: 24px;
            // }
            @include respond (col-md){
                font-size: 24px;
            }
        }
        h1,h2,h3,h4,h5,h6{
            color:$color-primary;
            font-family:font-fam-weight("Bold");
        }
        p{
            font-size:1.2rem;
        }
        .filter{
            letter-spacing: 1px;
            .top-filter{
                font-family: "Inter", sans-serif;
                font-weight: 500;
                position: relative;
                transition: all .2s;

            }
            .top-filter-active{
                position: relative;
                &::before{
                    background-color: #fff;
                    position: absolute;
                    content: "";
                    width: 100%;
                    height: 1.5px;
                    bottom: -3px;
                    left: 4px;
                    margin-left: auto;
                    margin-right: auto;
                    animation: lineSlide .2s linear 1 normal;
                    -webkit-animation: lineSlide .2s linear 1 normal;
                }
            }
            @keyframes lineSlide {
                0% {
                    width: 0;
                }
                100%{
                    width: 100%;
                }

            }
            @-webkit-keyframes lineSlide {
                0% {
                    width: 0;
                }
                100%{
                    width: 100%;
                }
            }


        }
        .search-form{
            .search-all{
                font-size: 16px;
                font-weight: 400;
                color: #707070;
                &::placeholder{
                    color: #9d9d9d;
                    font-size: 14px;
                    font-weight: 400;
                }
            }

        }
        .drop-search{
            &::-webkit-scrollbar{
                width: 5px;
            }
            &::-webkit-scrollbar-track{
                background-color: #F7F7F7;
            }
            &::-webkit-scrollbar-thumb{
                background-color: rgb(30 58 138);
                border-radius: 10px;
            }
            .drop-search-child{
                &:hover, &:active, &:focus{
                    background-color: rgb(30 58 138) !important;
                    color: white !important;
                }
            }

        }


    }
    .tour-collection{
        .grid-container-custom{
            transition: all .5s;
            aspect-ratio: 4/5;
            &:hover{
            //    transform: scale(1.01);
                .icon-on-img .redirect_wrap{
                    transform: translateX(0);
                    -webkit-transform: translateX(0);
                    -ms-transform: translateX(0);
                }
            }
            // height: 256px;
            // max-height: 270px;
            &::before{
                content: "";
                background-color: #000;
                opacity: 0.3;
                position:absolute;
                top:0;
                left:0;
                border-radius: 20px;
                width:100%;
                height:100%;
                z-index: 1;
            }

            .txt-on-img{
                z-index:999;
                &::before{
                    content:"";
                    background-color: $color-red-prim;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 2px;
                    // border: 1.5px solid $color-red-prim;
                    border-radius: 30px;
                    transition: transform (90deg);
                    z-index: 999;
                }
            }
            .icon-on-img{
                z-index: 999;
                .redirect_wrap{
                    transform: translateX(calc(-100% + 22px));
                    -webkit-transform: translateX(calc(-100% + 22px));
                    -ms-transform:translateX(calc(-100% + 22px));
                    transition: all 0.5s linear;
                    -webkit-transition: all 0.5s linear;
                    -ms-transition: all 0.5s linear;
                    &:hover {
                        transform: translateX(0);
                        -webkit-transform: translateX(0);
                        -ms-transform: translateX(0);
                    }
                }
                // .title_invisible{
                //     display: block;
                //     overflow: hidden;
                // }
                // &:hover{
                //     width: 80px;
                //     .title_invisible{
                //         display: block;
                //         transition: .5s linear .5s;

                //     }
                // }

            }
        }
    }
    .top_destination, .best_destination{
        background-color: #F7F7F7 !important;
        .title_dest{
            // letter-spacing: .3px;
            text-transform: capitalize;
            position: relative;
            font-size: 24px;
            font-weight: 600;
            &::before {
                content: ' ';
                width: 40px;
                height: 1.5px;
                background-color: $color-red-prim;
                position: absolute;
                bottom: -3px;
                left: 0;
            }
        }
        .top_destination_wrap{
            height: 350px;
            max-height: 380px;
            @include respond('col-md'){
                height: 380px;
            }
            // width: 245px;
            // border: 0.3px solid #F7F7F7;
            // background-color: #FFFFFF;

            .top_destination_child{
                height: 100%;
                width: 100%;
                background-color: #FFFFFF;
                transition: all .5s;
                &:hover{
                    box-shadow: rgba(67, 71, 85, 0.27) 0px 0px 0.25em, rgba(90, 125, 188, 0.05) 0px 0.25em 1em;
                    .img_details{
                        transform: scale(1.05);
                    }
                }
                .img_details{
                    transition: all .5s;
                    position: relative;
                    // height: 180px;
                    &::before{
                        content: "";
                        background-color: #000;
                        opacity: 0.3;
                        position:absolute;
                        top:0;
                        left:0;
                        border-top-right-radius: 10px ;
                        border-top-left-radius: 10px ;
                        width:100%;
                        height: 100%;
                        z-index: 1;
                    }
                    // &:hover{
                    //     transform: scale(1.05);

                    // }
                }
                .content_details{
                    align-items: start !important;
                    justify-content: start !important;
                    .stars{
                        margin-right: 0 !important;
                        .rating-star{
                            fill: #FFB000 !important;
                        }
                        .rating-star-empty{
                            fill: #707070;
                        }
                    }
                    .heading_details{
                        height: 45px;
                        font-size: 14px;
                        font-weight: 600;
                    }
                }
            }
        }

    }
    .best_destination{
        background-color: transparent !important;
        .best_dest_blocks{
            // .indian_dest_carousel{
            //     .owl-nav{
            //         // display: block;
            //     }
                .item{
                    overflow: hidden;
                    .best_dest_wrap{
                        transition: all .5s;
                        overflow: hidden;
                        &::before{
                            content: "";
                            background-color: #000;
                            opacity: 0.3;
                            position:absolute;
                            top:0;
                            left:0;
                            border-radius: 10px;
                            width:100%;
                            height:100%;
                            // z-index: -1;
                        }
                        &:hover{
                           transform: scale(1.05);
                           overflow: hidden;

                        }
                        // .best_dest_img{
                        //     transition: .5s;
                        //     &:hover{
                        //         transform: scale(1.05);
                        //         // border-radius: 10px;
                        //     }

                        // }
                    }
                }
            // }

        }
    }
    .Filter_activities{
        background: transparent;
        .title_filt{
            // width: max-content;
            // text-align: center;
            // border-bottom: 1.5px solid $color-red-prim;
            &::before {
                content: ' ';
                width: 60px;
                height: 1.5px;
                background-color: $color-red-prim;
                position: absolute;
                bottom: -3px;
                left: 48%;
            }
        }
        .filter_opt{
            .img_filt_parent{
                position: relative;
                border-radius: 50%;
                width: 59px;
                height: 59px;
                &::before{
                    content: "";
                    background-color: #000;
                    opacity: 0.3;
                    position:absolute;
                    top:0;
                    left:0;
                    border-radius: 50%;
                    width:100%;
                    height:100%;
                    z-index: 1;
                }
                .img_filt{
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 50%;
                    aspect-ratio: 1/1;
                    object-position: center;
                    margin-bottom: .5rem;
                }
            }
        }

    }
    .recently_browsed{
        .title_browse{
            &::before {
                content: ' ';
                width: 60px;
                height: 1.5px;
                background-color: $color-red-prim;
                position: absolute;
                bottom: -3px;
                left: 0;
            }
        }
        .grid_wrap{
            width: 100%;
            transition: all .5s;
            &:hover{
                transform: scale(1.05);
            }
            .grid_wrap_child{
                background-color: #F7F7F7;
                padding: .75rem;
                width: 100%;
                // aspect-ratio:16/9;
                // min-width: 248px;
                max-height: 140px;
                min-height: 138px;
                position: relative;
                .card_img_wrap{
                    // min-width: 89px;
                    width: 40%;
                    // aspect-ratio: 3/4;
                    height: 120px;
                    border-radius: 5px;
                    margin-right: 10px;

                    .card_img{
                        width: 100%;
                        height: 100%;
                        position: relative;
                        // display: block;
                        object-fit: cover;
                        overflow: hidden;
                        border-radius: 5px;
                        // aspect-ratio: 89/108;
                        &::before{
                            content: "";
                            background-color: #000;
                            opacity: 0.3;
                            position:absolute;
                            top:0;
                            left:0;
                            border-radius: 5px;
                            width:100%;
                            height:100%;
                            // z-index: -1;
                        }
                    }
                }
                .card_details_wrap{
                    min-width: 140px;
                    width: 50%;
                    // .card_head_text{

                    // }
                    .card_price{
                        position: relative;
                        .filt_price_old{
                            color: $color-red-prim;
                            &::after{
                                content:"";
                                background-color: $color-red-prim;
                                position: absolute;
                                top: 47%;
                                left: 0;
                                height: 1px;
                                width: 50%;
                                // border: 1.5px solid $color-red-prim;
                                // border-radius: 30px;
                                // transition: transform (90deg);
                                z-index: 9999;
                            }
                        }
                    }
                }
            }
        }
    }
    // .dropdown_filt_section{
    //     .bg_search{
    //         .bg_sort{
    //             .drop_cont{
    //                 display: none;
    //             }
    //         }
    //     }
    // }
    .search_result_container {

        .filter_list {
            height: max-content;
            @include respond("tab-landscape-max"){
                display: none;
                transform: translateX(-100%);
                -webkit-transform: translateX(-100%);
                -ms-transform: translateX(-100%);
                transition: linear .5s;
            }
            transition: linear .5s;
            &_active{
                @include respond("tab-landscape-max"){
                    display: block;
                    transform: translateX(0);
                   -webkit-transform: translateX(0);
                   -ms-transform: translateX(0);
                   transition: linear .5s

                }
            }

            .filter_list_wrap  {

                .sub_fiter_heading{
                    .sub_filter_title {
                        font-size: 16px;
                        font-weight: 700;
                        padding: 0.25rem 0;
                        color: #707070;
                        text-transform: capitalize;
                    }

                }
                .fiter_budget{
                    .range_wrapper{
                        // width: 100%;
                        // background: #fff;
                        // border-radius: 10px;
                        // padding: 20px 25px 40px;
                        /* box-shadow: 0 12px 35px rgba(0,0,0,0.1); */
                    }
                      /* header h2{
                        font-size: 24px;
                        font-weight: 600;
                      }
                      header p{
                        margin-top: 5px;
                        font-size: 16px;
                      } */
                    .price-input{
                        width: 100%;
                        display: flex;
                        /* margin: 30px 0 35px; */
                    }
                    .price-input .field{
                        display: flex;
                        width: 100%;
                        height: 45px;
                        align-items: center;
                        justify-content: center;
                    }
                    // .price-input .field_right{
                    //     >input{
                    //         text-align: end;
                    //     }
                    // }
                    .field input{
                        width: 100%;
                        height: 100%;
                        outline: none;
                        font-size: 15px;
                        color: #00000040;
                        /* margin-left: 12px; */
                        border: none;
                        -moz-appearance: textfield;
                    }
                    input[type="number"]::-webkit-outer-spin-button,
                    input[type="number"]::-webkit-inner-spin-button {
                        -webkit-appearance: none;
                    }

                    .slider{
                        height: 5px;
                        position: relative;
                        background: $color-primary-darker;
                        // background: #1C2B59;
                        border-radius: 5px;
                    }
                    .slider .progress{
                        height: 100%;
                        left: 25%;
                        right: 25%;
                        position: absolute;
                        border-radius: 5px;
                        background: $color-primary-darker;
                    }
                    .range-input{
                        position: relative;
                    }
                    .range-input input{
                        position: absolute;
                        width: 100%;
                        height: 5px;
                        top: -5px;
                        background: none;
                        pointer-events: none;
                        -webkit-appearance: none;
                        -moz-appearance: none;
                    }
                    input[type="range"]::-webkit-slider-thumb{
                        height: 17px;
                        width: 17px;
                        border-radius: 50%;
                        background: $color-red-prim;
                        pointer-events: auto;
                        -webkit-appearance: none;
                        /* box-shadow: 0 0 6px rgba(0,0,0,0.05); */
                    }
                    input[type="range"]::-moz-range-thumb{
                        height: 17px;
                        width: 17px;
                        border: none;
                        border-radius: 50%;
                        background: $color-red-prim;
                        pointer-events: auto;
                        -moz-appearance: none;
                        /* box-shadow: 0 0 6px rgba(0,0,0,0.05); */
                    }
                }
                .fiter_categories,.fiter_destination, .include_flight{
                    .checkbox_filter {
                        >input[type="checkbox"] {
                            background-color:#D9D9D9;
                            width:14px;
                            height:14px;
                            &::after{
                                content: "\f00c";
                                font-family: "Font Awesome 5 Free";
                                font-size: 10px;
                                font-weight: 900;
                                color: #fff;
                                display: none;
                                text-align: center;
                                // padding-left: 2px;
                            }
                            // &:checked{
                            //     background-color: #00A542;
                            // }

                        }
                        >input[type="checkbox"]:checked{
                            background-color: #00A542;
                        }
                        >input[type="checkbox"]:checked::after{
                            display: block;
                        }

                    }
                }
            }
        }

        .search_result_list  {
            .search_result_wrap {
                .result_img_container{
                   width: 100%;
                    // height: 175px;
                    position: relative;
                    overflow: hidden;
                    &::before{
                        content: "";
                        background-color: #000;
                        opacity: 0.2;
                        position:absolute;
                        top:0;
                        left:0;
                        border-radius: 10px;
                        width:100%;
                        height:100%;

                    }
                }
                .result_details_container {
                    // width: 50%;
                    overflow: hidden;
                    .travel_to_wrap {
                        .travel_to_parent {
                            .travel_to_child {
                                background-color: #CF2E2E1C;
                                padding: 2px 4px;
                                border-radius: 3px;
                                font-size: 10px;
                                font-weight: 500;
                                color: #000000;
                            }
                            // .travel_to_dir{

                            // }
                        }
                    }
                    .services {
                        overflow-x: hidden;
                        .services_wrap {
                            overflow-x: hidden;
                            .icon_services {
                                // width:100%;
                                width: 28px;
                                height: 28px;
                                >img{
                                    width: 100%;
                                    height: 100%;
                                    object-fit: cover;
                                }
                                >svg {
                                    fill: #1C2B59;
                                }
                            }
                        }
                    }
                }
                .result_review_container{
                    // width: 20%;
                    overflow: hidden;
                    .reviw_wrap{
                        @include respond (small-phone){
                            margin-left: 0 !important;
                        }
                        .review_top{
                           font-size: 10px;
                           font-weight: 500;
                           .review_bg{
                            // background-color: #00A542;
                                // font-size: 10px;
                                // @include respond (small-phone){
                                //     font-size: 8px;
                                // }
                           }
                        }
                        .stars {
                           .rating-star {
                                fill: #FFB000 !important;
                            }
                            .rating-star-empty{
                                fill: #707070;
                            }
                        }
                        .result_price_old{
                            font-size: 13px;
                            font-weight: 500;
                            color: $color-red-prim;
                            &::after{
                                content:"";
                                background-color: $color-red-prim;
                                position: absolute;
                                top: 45%;
                                left: 0;
                                height: 1px;
                                width: calc(100% + 2px) ;
                                z-index: 99;
                            }
                        }
                        .result_price_new{
                            font-size: 14px;
                            font-weight: 500;
                            color: #000000;
                        }

                    }
                    .card_btn {
                        // width: 80%;
                        height: 36px;
                        border-radius: 40px;
                        background-color: $color-red-prim;
                        @include respond (small-phone){
                            height: 25px;
                            font-size: 10px !important;
                            font-weight: 600 !important;
                            margin-left: 0 !important;
                        }
                        >button{
                            padding: 5px, 25px, 5px, 25px;
                            color: #FFFFFF;
                            @include respond (small-phone){
                                padding: 3px 10px !important;
                                font-weight: 600 !important;
                            }

                        }

                    }
                }
            }
        }
    }
    .filter_recommendation {
        .title_recommendation {
            &::before {
                content: ' ';
                width: 60px;
                height: 1.5px;
                background-color: $color-red-prim;
                position: absolute;
                bottom: -3px;
                left: 0;
            }
        }
        .recommendation_block{
            .recommendation_block_wrap{
                transition: .5s;
                &:hover{
                    transform: scale(1.05);
                }
                &::before{
                    content: "";
                    background-color: #000;
                    opacity: 0.2;
                    position:absolute;
                    top:0;
                    left:0;
                    border-radius: 10px;
                    width:100%;
                    height:100%;
                    z-index: -1;
                }
                .recommendation_block_img{
                    border-radius: 10px;
                }
            }
        }
    }
    .itineary{
        // .gallery_dtl{
        //     position: relative;
        //     &::before{
        //         content: "";
        //         background-color: #000;
        //         opacity: 0.3;
        //         position:absolute;
        //         top:0;
        //         left:0;
        //         border-radius: 10px;
        //         width:100%;
        //         height:100%;

        //     }
        // }
        .tab_options_wrap{

            .tab_options{
                // font-weight: 550;
                position: relative;
                &::before{
                    content: '';
                    position: absolute;
                    bottom: -16%;
                    left: 0;
                    height: 1px;
                    width: 100%;
                    background-color: $color-grey-prim-35;
                    // opacity: 0.4;
                }
                .tab{
                    position: relative;
                    // width: max-content;
                    // border-bottom: 1px solid $color-red-prism;
                }
                .tab_active{
                    position: relative;
                    &::before{
                        position: absolute;
                        width: 100%;
                        left: 0;
                        bottom: -5px;
                        content: '';
                        height: 2px;
                        background: $color-red-prim;
                    }

                }
            }
        }
        .itineary_cont{
            .rotate{
                -moz-transition: all .5s linear;
                -webkit-transition: all .5s linear;
                transition: all .5s linear;
                .svg_rotate{
                    transition: all .5s linear;
                }
                &.up{
                    transform: rotate(180deg);
                }
            }
            #ItinearyDiv{
                .iten_item{
                    .iten_body{
                        .iten_img{
                            // width: 140px;
                            position: relative;
                            aspect-ratio: 1.33/1;
                            &::before{
                                content: "";
                                background-color: #000;
                                opacity: 0.2;
                                position: absolute;
                                top: 0;
                                left: 0;
                                border-radius: 10px;
                                width: 100%;
                                height: 100%;
                                z-index: 1;
                            }
                        }
                    }

                }
            }

        }
        .up{
            transform: rotate(180deg);
        }
        .faq_cont{
            .faq_wrapper{
                .faq {
                    list-style:none;
                    padding-left:40px;
                    padding-right:20px;
                    .faq_li {

                        margin-bottom:15px;
                        &.faq_active {
                            .answer {
                                display: block;

                            }

                        }
                    }

                    .answer {
                        display: none;
                        overflow: hidden;

                    }
                    .plus-minus-toggle {
                        cursor: pointer;
                        height: 10px;
                        position: absolute;
                        width: 10px;
                        left:-40px;
                        top:50%;
                        z-index:2;

                        &:before, &:after {
                            background: #000;
                            content: '';
                            height: 2px;
                            left: 0;
                            position: absolute;
                            top: 0;
                            width: 10px;
                            transition: transform 500ms ease;
                        }

                        &:after {
                            // transform-origin: center;
                            transform: rotate(90deg);
                        }
                        &:before {
                            transform: rotate(180deg);
                        }

                        &.collapsed {
                            &:after {
                                visibility: hidden;

                            }


                        }


                    }
                }


            }
        }
        .ticket_wrap{
            .price_old2{
                color: $color-red-prim;
                &::before{
                    content: '';
                    background-color: $color-red-prim;
                    height: 1px;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    top: 50%;

                }
            }
            .ribbon_cont{
                right: -0.74rem;
                &::after{
                    content: "";
                    position: absolute;
                    top: -6px;
                    border-bottom: 6px solid rgb(65, 91, 73);
                    // box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
                    right: 0px;
                    border-right: 12px solid transparent;
                }

            }


        }

    }
    .offer_option_sec{
        .offer_option{
            position: relative;
            .offer_nav{
                border: 1.5px solid $color-grey-prim;
                transition: all .3s;
                // &:hover{
                //     border: 1.5px solid $color-red-prim;
                //     .offer_nav-a{
                //         color: $color-red-prim;
                //     }
                // }
                // >.offer_nav-a {
                //     &:hover{
                //         color: $color-red-prim;
                //     }
                // }
            }
            .offer_nav_active{
                border: 1.5px solid $color-red-prim;
                >.offer_nav-a{
                    color: $color-red-prim;
                }
            }
        }
    }
    .loaderOffer{
        height: 100vh;
        text-align: center;
        background-color: #FFFFFF;


        .loader-s {
            width: 48px;
            height: 48px;
            border: 5px solid #B4B4B3;
            border-radius: 50%;
            display: inline-block;
            box-sizing: border-box;
            position: relative;
            animation: pulse 1s linear infinite;
            &::after{
                content: '';
                position: absolute;
                width: 48px;
                height: 48px;
                border: 5px solid #B4B4B3;
                border-radius: 50%;
                display: inline-block;
                box-sizing: border-box;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                animation: scaleUp 1s linear infinite;
            }

            @keyframes scaleUp {
                0% { transform: translate(-50%, -50%) scale(0) }
                60% , 100% { transform: translate(-50%, -50%)  scale(1)}
            }
            @keyframes pulse {
                0% , 60% , 100%{ transform:  scale(1) }
                80% { transform:  scale(1.2)}
            }
        }
    }


    .offer_section{
        .offer_grid{
            width: 100%;
            display: grid;
            grid-gap: 24px;
            grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
            grid-auto-rows: 27px;
            .offer_content_wrap{
                border-radius: 20px;
                .offer_content{
                    // border: 0.5px solid $color-grey-prim-35;
                    border-radius: 20px;

                    &::before{
                        content: '';
                        background-color: #000;
                        opacity: 0.2;
                        position: absolute;
                        top: 0;
                        left: 0;
                        border-radius: 20px;
                        width: 100%;
                        height: 100%;
                        // height: fit-content;
                        // z-index: 1;
                    }

                }
            }

            .offer_ribbon{
                width: 150px;
                height: 150px;
                overflow: hidden;
                position: absolute;
                &::before, &::after{
                    position: absolute;
                    z-index: -1;
                    content: '';
                    display: block;
                    // border: 8px solid #ac1717;
                    border: 3px solid #ac1717;
                }
                > span{
                    position: absolute;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    // width: 225px;
                    width: 140px;
                    height: 29px;
                    // padding: 3px 2px;
                    background-color: #CF2E2E;
                    // box-shadow: 0 5px 10px rgba(0,0,0,.1);
                    // box-shadow: rgba(0, 0, 0, 0.24) 2px 0px 2px;
                    box-shadow: 0 3px 10px -5px #000;
                    color: #fff;
                    // text-shadow: 0 1px 1px  rgba(0, 0, 0, 0.24);
                    text-transform: uppercase;
                    text-align: center;

                }

            }

            .ribbon-top-left {
                // top: -10px;
                // left: -10px;
                top: -5px;
                left: -5px;
                &::before {
                    border-top-color: transparent;
                    border-left-color: transparent;
                    top: 0;
                    // left: 93px;
                    // top: 100%;
                    right: 33.5%;
                    // &:nth-child(even){
                    //     right: 34.5%;
                    // }
                }

                &::after{
                    border-top-color: transparent;
                    border-left-color: transparent;
                    bottom: 50px;
                    left: 0;
                }
                > span{
                    left: -30px;
                    top: 25px;
                    transform: rotate(-45deg);
                }
            }
            .ribbon-top-right{
                // top: -10px;
                // right: -10px;
                top: -5px;
                right: -5px;
                text-align: right;
                &::before{
                    border-top-color: transparent;
                    border-right-color: transparent;
                    top: 0px;
                    // right: 93px;
                    // top: 100%;
                    left: 33.35%;
                    // &:nth-child(even){
                    //     left: 34.5%;
                    // }
                }
                &::after{
                    border-top-color: transparent;
                    border-right-color: transparent;
                    bottom: 50px;
                    right: 0;

                }
                > span{
                    // left: -25px;
                    // top: 30px;
                    top: 25px;
                    right: -30px;
                    transform: rotate(45deg);
                }
            }

        }

    }

}
// .holidaybooking_details{
//     display: flex;
//     flex-direction: column;
//     border: 3px solid $color-primary;
//     border-radius: 5px;

//     .booking_details_header{

//         padding: 1rem 4rem;
//         border-bottom: 1px solid lighten($color-grey, 30%);
//         border-bottom: 1px solid rgba($color-primary-dark, 0.2);
//         background-color: #f0f0f0;
//         background-color: lighten($color-secondary-lighter, 2%);
//         background-color: $color-grey-light-1;
//         display: flex;
//         flex-wrap: wrap;
//         align-items: center;

//         @include respond(tab-landscape) {
//             display: grid;
//             grid-gap: 5px;
//         }

//         @include respond(col-md) {
//             padding: 1rem 2rem;
//         }

//     }
//     .booking_details_content{
//         padding: 1rem 2rem;
//         table td {
//             vertical-align: middle !important;
//         }

//         table thead tr {
//             border-bottom: 1px solid rgba($color-primary-dark, 0.2);
//         }
//         .table-trBorder tbody tr:last-child {
//             border-top: 1px solid lighten($color-grey, 30%);
//         }


//     }
// }
