.svg {
    &_small {
        width: 12px;
        height: 12px;
    }
    &_grey {
        fill: lighten($color-grey, 15%);
    }
    &_white {
        fill: #fff;
    }
    &_red{
        fill: $color-red-prim;
    }
    &_large {
        width: 2rem;
        height: 2rem;
    }
    &_med {
        width: 1rem;
        height: 1rem;
    }
    &_med_a{
        width: 2.2rem;
        height: 1rem;
    }
    &_sec {
        fill: $color-secondary;
    }
    &_primary {
        fill: $color-primary;
    }
    &_red {
        fill: $color-red;
    }
    &_airlines-vertical-reverse {
        transform: rotate(-90deg);
    }
    &_airlines-vertical {
        transform: rotate(90deg);
    }
    &_airlines-45 {
        transform: roatate(45deg);
    }
}
