%heading {
    display: inline-block;
}

// .heading_1 {
//     font-size: 30px;
// }

// .heading_2 {
//     font-size: 22px;
// }

// .heading_3 {
//     @extend %heading;
//     font-size: 18px;
// }

.heading_4 {
    @extend %heading;
    font-size: 18px;
}


.w-50{
    width: 50%;
}

.font-weight-500{
font-weight: 500;
}
