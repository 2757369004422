.document {
    li {
        list-style-type: number !important;
    }
}



.progress-line-visa {
    position: relative;
    margin: 0 auto;
    border-radius: 10px;
    width: 90%;
    background: grey;
    height: 5px;
    z-index: -1;
    display: flex;
    justify-content: space-between;

    .progress-visa {
        position: absolute;
        width: 75%;
        height: 100%;
        background: orange;
        transition: width 1s ease-in-out;
    }
}

.status {
    position: relative;
    top: -9.5px;

    .dot {
        width: 20px;
        height: 20px;
        border-radius: 50%;
        background: white;
        border: 5px solid grey;
        cursor: pointer;

        &.current {
            background: white;
            border: 5px solid orange;
        }

        &.completed {
            position: relative;
            background: orange;
            border: 5px solid orange;

            &::after {
                content: '';
                position: absolute;
                top: calc(50% - 5px);
                left: calc(50% - 5.5px);
                height: 5px;
                width: 10px;
                border-left: 2px solid white;
                border-bottom: 2px solid white;
                transform-origin: center;
                transform: rotate(-45deg);
                z-index: 2;
            }
        }

    }

}
