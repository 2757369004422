.accordion {
    &_book-flight {
        padding: 1rem 0.5rem;
        border-bottom: 1px solid $color-grey-light-1;
        &-title {
            font-size: 1rem;
            font-weight: bold;
        }
    }
}

.panel {
    &_book-flight {
        padding: 1.5rem 0;
    }
}

.general {
    &_trigger {
        margin: 0;
        padding: 1rem;
        // border-bottom: 1px solid $color-grey-light-1;
        cursor: pointer;
        font-size: 18px;
        font-family: $font-heading;
        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all 0.4s;
        position: relative;
        z-index: 1;
        transition: all 0.2s;
        background-color: $color-primary;
        margin-bottom: 0.5rem;
        color: #fff;
        &:hover {
            background-color: lighten($color-secondary, 5%);
            color: #fff;
        }
        &:hover .general_trigger-icon {
            &:before,
            &:after {
                background-color: #fff;
            }
        }
        &-active {
            background-color: lighten($color-secondary, 5%);
            color: #fff;
            .general_trigger-icon {
                position: relative;

                &:before,
                &:after {
                    background-color: #fff;
                }
                &:after {
                    transform: translateY(-50%) rotate(180deg);
                }
                &:before {
                    opacity: 0;
                    visibility: hidden;
                }
            }
        }
        &-icon {
            position: relative;
            width: 14px;
            height: 14px;
            display: inline-flex;
            z-index: -1;

            &:before,
            &:after {
                position: absolute;
                content: "";
                border-radius: 5px;
                background-color:#fff;
                top: 50%;
                left: 0;
                width: 100%;
                height: 2px;
                transition: all 0.2s;
            }
            &:before {
                // top: 50%;
                // left: 0;
                transform: translateY(-50%);
            }
            &:after {
                // left: 50%;
                // top: 0;
                transform: translateY(-50%) rotate(90deg);
            }
        }
    }
    &_panel {
        max-height: 0;
        overflow: hidden;
        transition: all 0.4s;
        &-active {
            padding: 1rem 0;
        }
    }
}
