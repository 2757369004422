.content_home {
    .counter_container {
        label {
            color: #000;
        }
    }
}
.counter {
    &_container {
        display: flex;
        flex-direction: column;
        color: $color-font-dark;
        label {
            text-align: left;
            font-size: 14px;
            letter-spacing: 0.5px;
            white-space: nowrap;
        }
        @media (max-width: 991px) {
            margin-top: 1rem;
        }
    }
    &_actions {
        display: flex;
        height: 40px;
        line-height: 40px;
        // grid-template-columns: repeat(3, 1fr);
        // grid-template-rows: 40px;
        // align-items: center;
        // justify-items: center;
        border: 1px solid lighten($color-grey, 20%);
        border-radius: 5px;
        transition: all 0.4s;
        background-color: rgba(255, 255, 255, 1);
        &:hover {
            border: 1px solid $color-red-prim;
        }
        &:hover .counter_plus {
            border-left: 1px solid $color-red-prim;
        }
        &:hover .counter_minus {
            border-right: 1px solid $color-red-prim;
        }
    }
    &_action {
        flex: 0 0 33.33%;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        color: $color-font-dark;
        transition: all 0.4s;
        &::selection {
            background-color: transparent;
            color: $color-secondary;
        }
        &-input {
            width: 33.33%;
            border: none;
            text-align: center;
            background-color: transparent;
        }
        &-svg {
            width: 1.1rem;
            height: 1.1rem;
        }
        &:hover &-svg {
            fill: $color-red-prim;
        }
    }
    &_plus,
    &_minus {
        font-size: 1.5rem;
        &:hover {
            color: $color-secondary;
            // background-color: $color-secondary;
        }
    }
    &_plus {
        border-left: 1px solid lighten($color-grey, 20%);
    }
    &_minus {
        border-right: 1px solid lighten($color-grey, 20%);
    }
    &_count {
    }
}
