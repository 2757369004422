.serv-wrapper {
    // background-color: #fff;
    padding: 0 0 1rem 0;
    .icon-wrap {
        background-color: transparent;
    }
    &:hover .icon-wrap {
        background-color: transparent;
    }
}

.services-section {
    .serv-wrapper {
        &:hover .icon-wrap {
            outline: 5px solid $color-secondary;
        }
        .icon-wrap {
            height: 150px;
            overflow: hidden;
            position: relative;
            transition: all 0.2s;

            &:after {
                // position: absolute;
                // width: 200px;
                // height: 200px;
                // background-color: $color-secondary;
                // content: "";
                // display: inline-block;
                // top: 50%;
                // left: 50%;
                // transform: translate(-50%, -50%);
                // z-index: -1;/
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &-info h3 {
            font-family: $font-heading;
            text-transform: capitalize;
        }
    }

    h3 {
        font-family: $font-heading;
    }
}

.card-tours .card-body h2:before,
.card-tours .card-body h3:before,
.card-tours .card-body h4:before,
.card-tours .card-body h5:before,
.card-tours .card-body h6:before {
    background-color: $color-secondary;
}

.bg-section h1,
.bg-section h2,
.bg-section h3,
.bg-section h4,
.bg-section h5,
.bg-section h6 {
    color: $color-primary;
}

.product-content .book-button a {
    color: $color-primary;
}

.card-tours {
    box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    transition: all 0.4s;
    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 0.5rem 0.25rem rgba(0, 0, 0, 0.075) !important;
    }
}

.card-reviews {
    &-text {
        p {
            position: relative;
            // text-indent: 1rem;
            svg {
                position: absolute;
                top: -1.5rem;
                left: 5px;
                width: 1.5rem;
                height: 1.5rem;
            }
        }
    }
}

.card-tours {
    &:hover .card-body h2 {
        padding-left: unset;
    }
    &:hover .card-header {
        filter: unset;
    }
    border-radius: 0.75rem;
    .card-body {
        padding-top: 0;
        padding-bottom: 0;
        background-color: #fff;
        border-radius: 0.5rem;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        p {
            // margin-bottom: 1rem;
        }
        h2 {
            height: unset;
            margin-bottom: 0;
            &:before {
                display: none;
            }
        }
        &-heading {
            margin-bottom: 0;
            a {
                display: inline-block;
                width: 100%;
                height: 100%;
                padding: 0.5rem 2rem;
                text-decoration: none;
                @include respond(tab-landscape) {
                    padding: 0.5rem 1.5rem;
                }

                @include respond(phone) {
                    padding: 1rem;
                }
                background-color: $color-secondary;
                color: #fff;
                font-family: $font-heading;
            }
        }
    }
    .card-header {
        &-link-169 {
            padding-top: 56.25%;
            position: relative;
            img {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-bottom-left-radius: 0;
                border-bottom-right-radius: 0;
            }
        }
    }
}
.card-body-padding {
    > p {
        margin: 0;
    }
    padding: 1rem 2rem;
    @include respond(tab-landscape) {
        padding: 1rem 1.5rem;
    }

    @include respond(phone) {
        padding: 1rem;
    }
    background-color: lighten($color-secondary-lighter, 5%);
}

.card-blog.card-tours {
    box-shadow: none;
    // border: 1px solid rgba($color-primary-dark, 0.2);
    .card-body {
        border: 1px solid rgba($color-primary-dark, 0.2);
        border-top: none;
    }
}

.card-blog-author {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
    .selection_divider {
        background-color: #eaeaea;
    }
}

.section-header.line-up:before {
    background: $color-secondary;
}

.section-header.line-left:before {
    background: $color-secondary;
}

section.services-section,
section.popular-tours,
section.reviews-section {
    padding: 30px 0;
}

section.services-section {
    background-color: #f3faff;
}

section.popular-tours {
    // background-color: $color-secondary-lighter;
}

.section-divider {
    margin: 0;
}

.section-header {
    font-family: $font-heading;
}

.top-flight{
padding: 4rem 0;
}

// Reviews carousel
.card-reviews .card-body {
    background: #fff;
    .card-reviews-img {
        width: 107px;
        height: 107px;
        border-radius: 100%;
        margin: 0 auto;
        overflow: hidden;
        margin-bottom: 1rem;
    }
    img {
        position: relative;
        margin: 0 auto;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 100%;
        display: inline-block;
        border-radius: unset;
        left: unset;
        top: unset;
        transform: unset;
        border: unset;
        // left: 50%;
        // top: -40px;
        // transform: translate(-50%, 0%);
        /* border: 0.5rem solid #fff; */
    }
}



//ankit
.inspire_section{
    .inspiration_wrap{
&:hover .rounded_arrow_hover{
        // opacity: 1 !important;
        // visibility: visible !important;
        max-height: max-content !important;
        transform: translateY(0);
}
&:hover .short_desp{
    // opacity: 1 !important;
    // visibility: visible !important;
    transform: translateY(-10px);
}

.inspiration-child{
    position: relative;
    &::before{
    content: "";
    width: 100%;
    height:100%;
    background-color: rgba(#000,0.2);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 20px;
}

}
.rounded_arrow_hover{
    transition: .4s transform;
}
.short_desp{
    transition: .4s  transform;

}
    }
}


.top_flight_tags{
    &_title{
        position: relative;
    &::before{
        content: "";
        position: absolute;
        top: -7px;
        left:-26px;
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 1rem solid transparent;
       border-bottom: 1rem solid transparent;

       border-right:1rem solid $color-red-prim;
    }
}
}

.side_haldle_box {
    position: fixed;
    transform: rotate(270deg) translateX(-300px);
    transform-origin: left top;
    transition: .4s transform;
    @include respond(tab-portrait){
        transform: rotate(270deg) translateX(-300px) translateY(-26px);
    }
    &_open{
        @include respond(tab-portrait){
            transform: rotate(270deg) translateX(-300px) translateY(0);
        }
    }
}

