.search_flight-results-box-dom {
    > div:first-child {
        padding: 1.5rem;
        padding-bottom: 0;
        grid-template-columns: 60fr 40fr;
        grid-template-columns: 1fr max-content;
        @include respond(small-desktop) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-columns: 1fr;
        }

        @include respond(col-md) {
            grid-template-rows: repeat(4, max-content);
        }
    }

    .search_flight {
        &-info {
            grid-gap: 1rem;
            @media (max-width: 1300px) and (min-width: 1200px) {
                grid-template-rows: repeat(2, min-content);
            }

            @include respond(col-md) {
                grid-template-rows: repeat(2, min-content);
            }
        }

        &-details-container {
            align-self: flex-start;
            &:before {
                display: block;
                @include respond(col-md) {
                    display: none;
                }
            }
            justify-self: center;
            @include respond(small-desktop) {
                // justify-self: end;
            }
            @include respond(col-md) {
                grid-row: 1 / span 1;
                grid-column: 2 / -1;
                justify-self: start;
                grid-template-columns: repeat(3, max-content);
                grid-gap: 0.5rem;
                align-items: center;
            }
            @include respond(small-phone) {
                grid-row: 2 / span 1;
                grid-column: 1 / -1;
            }
            .search_flight-price {
                @include respond(col-md) {
                    grid-column: 1 / span 1;
                    grid-row: 3 / span 1;
                }
            }

            .search_flight-baggage-luggage {
                @include respond(col-md) {
                    grid-column: 1 / span 1;
                    grid-row: 2 / span 1;
                }
                @include respond(small-phone) {
                    grid-column: 2 / span 1;
                    grid-row: 3 / span 1;
                }
            }

            .search_flight-class {
                @include respond(col-md) {
                    grid-column: 1 / span 1;
                    grid-row: 1 / span 1;
                }
                @include respond(small-phone) {
                    grid-column: 3 / span 1;
                    grid-row: 3 / span 1;
                }
            }
        }

        &-via {
            @media (max-width: 1300px) and (min-width: 1200px) {
                grid-row: 2 / span 1;
                grid-column: 1 / -1;
            }

            @include respond(col-md) {
                grid-row: 2 / span 1;
                grid-column: 1 / -1;
            }
        }
        &-book {
            grid-column: 2 / span 1;
            @include respond(col-md) {
                grid-row: 4 / span 1;
                grid-column: 2 / -1;
                justify-self: start;
            }
            @include respond(small-phone) {
                grid-row: 3 / span 1;
                grid-column: 1 / -1;
                justify-self: start;
            }
            .button {
                &:before,
                &:after {
                    @include respond(col-md) {
                        display: none;
                    }
                }
                @include respond(col-md) {
                    background-color: $color-primary;
                }
            }
        }

        &-details {
            @include respond(col-md) {
                grid-row: 4 / span 1;
                grid-columns: 1 / -1;
            }
        }

        &-airlines-logo {
            grid-template-columns: 40px 1fr 40px;
        }
    }

    // Media queries for domestic search flight results
}
