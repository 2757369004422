.search_terms {
    &-heading {
        background-color: $color-secondary-lighter;
        font-size: 18px;
        padding: 1rem;
        margin-bottom: 1rem;
        &:not(:first-child) {
            margin-top: 1rem;
        }
    }
    p {
        padding-left: 1rem;
        line-height: 1.8;
    }
    p + p {
        text-indent: 1em;
    }
    &-checkbox {
        display: none;
    }
}
